import "./wizard-style.css";

interface WizardProps {
  titles: string[];
  initialStep: number;
  onStepClick: (step: number) => void;
}
export const Wizard = (props: WizardProps) => {
  const { titles, initialStep, onStepClick } = props;

  return (
    <>
      <div className="react-form-wizard">
        <div className="wizard-navigation">
          <div className="wizard-progress-with-circle">
            <div className="wizard-progress-bar"></div>
          </div>
          <ul className="form-wizard-steps  wizard-nav wizard-nav-pills  md">
            {titles.map((el: any, index: number) => (
              <li
                className={
                  initialStep >= index
                    ? initialStep == index
                      ? "current active"
                      : "active"
                    : ""
                }
              >
                <span
                  onClick={() => {
                    onStepClick(index);
                  }}
                  style={{ alignItems: "center" }}
                  className={"active"}
                >
                  <div className="wizard-icon-circle md checked " role="tab">
                    <div className="wizard-icon-container ">
                      <span className="wizard-icon">
                        <i className="ti-user">{index + 1}</i>
                      </span>
                    </div>
                  </div>
                  <span className="stepTitle active">{el}</span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
