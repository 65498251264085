import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from "reactstrap";
import { currentUserSelector } from "../redux/reducers/userSlice";
import { useAppSelector } from "../redux/store";

interface AppMenuProps {
  onMenuItemClick: any;
}
export default function AppMenu({ onMenuItemClick }: AppMenuProps) {
  const [expanded, setExpanded] = useState(null);
  const currentUser = useAppSelector(currentUserSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();

  let menu: {
    text: string;
    link: string | null;
    external?: boolean;
    icon: string | null;
    child?: any;
  }[] = [
    {
      text: t("menu.home"),
      link: "/",
      external: true,
      icon: null,
    },
  ];

  const isAdministrator = currentUser?.authorityChecks.isAdministrator;

  const isSportellista = currentUser?.authorityChecks?.isSportellista;

  const isProfessionista = currentUser?.authorityChecks?.isProfessionista;

  const isOperatore = currentUser?.authorityChecks?.isOperatore;

  const isUser = currentUser?.authorityChecks?.isUser;

  if (isAdministrator) {
    menu = [
      {
        text: t("menu.home"),
        link: "/",
        external: true,
        icon: null,
      },

      {
        text: t("menu.survey"),
        external: true,
        icon: null,
        link: "",
        child: [
          {
            text: t("menu.survey-template"),
            link: "/survey-template",
            external: true,
            icon: null,
          },
          {
            text: t("menu.survey-answer"),
            link: "/survey-answer",
            external: true,
            icon: null,
          },
        ],
      },
      {
        text: t("menu.user"),
        external: true,
        icon: null,
        link: "",
        child: [
          {
            text: t("menu.utenti"),
            link: "/user",
            external: true,
            icon: null,
          },
          {
            text: t("menu.appointment"),
            link: "/appointment",
            external: true,
            icon: null,
          },
          {
            text: t("user.badge.adminlist"),
            link: "/badge",
            external: true,
            icon: null,
          },
        ],
      },
      {
        text: t("menu.accomodation"),
        link: "",
        external: true,
        icon: null,
        child: [
          {
            text: t("menu.accomodation"),
            link: "/accomodation",
            external: true,
            icon: null,
          },
          {
            text: t("menu.accomodation-owner"),
            link: "/accomodation-owner",
            external: true,
            icon: null,
          },
          {
            text: t("menu.real-estate-agency"),
            link: "/real-estate-agency",
            external: true,
            icon: null,
          },
          {
            text: t("menu.accomodation-inspection"),
            link: "/accomodation-inspection",
            external: true,
            icon: null,
          },
        ],
      },
    ];
  } else if (isSportellista || isOperatore) {
    menu = [
      {
        text: t("menu.home"),
        link: "/",
        external: true,
        icon: null,
      },
      {
        text: t("menu.survey-answer"),
        link: "/survey-answer",
        external: true,
        icon: null,
      },
      {
        text: t("menu.user"),
        external: true,
        icon: null,
        link: "",
        child: [
          {
            text: t("menu.utenti"),
            link: "/user",
            external: true,
            icon: null,
          },
          {
            text: t("menu.appointment"),
            link: "/appointment",
            external: true,
            icon: null,
          },
        ],
      },
    ];

    if (isSportellista || isOperatore) {
      menu.push({
        text: t("menu.accomodation"),
        link: "",
        external: true,
        icon: null,
        child: [
          {
            text: t("menu.accomodation"),
            link: "/accomodation",
            external: true,
            icon: null,
          },
          {
            text: t("menu.accomodation-owner"),
            link: "/accomodation-owner",
            external: true,
            icon: null,
          },
          {
            text: t("menu.real-estate-agency"),
            link: "/real-estate-agency",
            external: true,
            icon: null,
          },
          {
            text: t("menu.accomodation-inspection"),
            link: "/accomodation-inspection",
            external: true,
            icon: null,
          },
        ],
      });
    }
  } else if (isProfessionista) {
    menu = [
      {
        text: t("menu.home"),
        link: "/",
        external: true,
        icon: null,
      },
      {
        text: t("menu.survey-answer"),
        link: "/survey-answer",
        external: true,
        icon: null,
      },
      {
        text: t("menu.appointment"),
        link: "/appointment",
        external: true,
        icon: null,
      },
      {
        text: t("menu.badge-in-out"),
        link: "/user/badge-in-out",
        external: true,
        icon: null,
      },
    ];
  }

  const handleItemClick = (link: string) => {
    onMenuItemClick();
    navigate(link);
  };

  return (
    <Nav className="mr-auto" navbar>
      {menu.map((el: any, idx: number) => {
        if (el.child !== undefined) {
          return (
            <Dropdown
              nav
              isOpen={el.text == expanded}
              toggle={() => {
                if (el.text == expanded) setExpanded(null);
                else setExpanded(el.text);
              }}
              key={idx}
            >
              <DropdownToggle nav caret>
                {el.icon != null ? (
                  <>
                    <FontAwesomeIcon
                      style={{ marginRight: "10px" }}
                      icon={el.icon}
                    />{" "}
                  </>
                ) : (
                  ""
                )}
                {el.text}
              </DropdownToggle>
              <DropdownMenu>
                {el.child.map((ch: any) => {
                  return (
                    <DropdownItem>
                      <a
                        className="nav-link"
                        href={ch.link}
                        rel="noopener noreferrer"
                      >
                        {ch.text}
                      </a>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          );
        } else {
          return el.external ? (
            <li className="nav-item" key={idx}>
              <a
                className="nav-link"
                href={el.link}
                rel="noopener noreferrer"
                onClick={onMenuItemClick}
              >
                {el.text}
              </a>
            </li>
          ) : (
            <NavItem>
              <NavLink to={el.link} onClick={() => handleItemClick(el.link)}>
                {el.icon != null ? (
                  <>
                    <FontAwesomeIcon
                      style={{ marginRight: "10px" }}
                      icon={el.icon}
                    />{" "}
                  </>
                ) : (
                  ""
                )}
                {el.text}
              </NavLink>
            </NavItem>
          );
        }
      })}
    </Nav>
  );
}
