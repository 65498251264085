import { AxiosRequestConfig } from "axios";
import {
  CreateAccomodationInspectionImage,
  DetailAccomodationInspectionImage,
} from "../model/accomodation-inspection-image.model";
import { defaultHeaders } from "./common";

export const getAccomodationInspectionImageListConf = (query: string) => {
  return {
    method: "get",
    url: "/api/accomodation-inspection-image" + query,
    headers: defaultHeaders,
  };
};

export const getDetailAccomodationInspectionImageConf = (id: string) => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection-image/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailAccomodationInspectionImageConf = (
  accomodationImage: DetailAccomodationInspectionImage
) => {
  return {
    method: "put",
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection-image/${accomodationImage.id}`,
    headers: defaultHeaders,
    data: accomodationImage,
  };
};

export const createDetailAccomodationInspectionImageConf = (
  accomodationInspectionImage: CreateAccomodationInspectionImage
): AxiosRequestConfig => {
  const { accomodationInspectionId, image } = accomodationInspectionImage;
  const form = new FormData();
  form.append("accomodationInspectionId", accomodationInspectionId.toString());
  image && form.append("file", image);
  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection-image`,
    headers: {
      "content-type": "multipart/form-data",
    },
    data: form,
  };
};
export const deleteAccomodationInspectionImageConf = (id: string) => {
  return {
    method: "delete",
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection-image/` + id,
    headers: defaultHeaders,
  };
};
