import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { networkStatusSelector } from "../redux/reducers/networkStatusSlice";

const OfflineAlert = () => {
  const { t } = useTranslation();
  const { isOffline } = useSelector(networkStatusSelector);
  return (
    <div className="offline-alert">
      {isOffline && <Alert color="danger">{t("general.errors.offline")}</Alert>}
    </div>
  );
};

export default OfflineAlert;
