import { Route } from "react-router-dom";
import { currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import ErrorBoundaryRoutes from "../../shared/error-boundary-routes";
import PageNotFound from "../../shared/page-not-found";
import AccomodationDetailPage from "./accomodation-detail.page";
import AccomodationListPage from "./accomodation-list.page";

const AccomodationRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isProfessionista = currentUser?.authorityChecks.isProfessionista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;

  return (
    <ErrorBoundaryRoutes>
      {currentUser && (isSportellista || isAdministrator || isOperatore) && (
        <>
          <Route path="" element={<AccomodationListPage />} />
          {!isOperatore && (
            <Route path="/create" element={<AccomodationDetailPage edit />} />
          )}
          <Route path=":id" element={<AccomodationDetailPage />} />
          {!isOperatore && (
            <Route path=":id/edit" element={<AccomodationDetailPage edit />} />
          )}
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default AccomodationRoutes;
