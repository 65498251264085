import { createAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface NetworkStatusState {
  isOffline: boolean;
  pendingActions: any[];
}

const initialState: NetworkStatusState = {
  isOffline: false,
  pendingActions: [],
};

export const setOnline = createAction<boolean>("network/status");

// export const addPendingAction = createAction<any>('networkStatus/addAction')
export const networkStatusSlice = createSlice({
  name: "networkStatus",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addPendingAction: (state, action: any) => {
      state.pendingActions = [
        ...state.pendingActions.filter(
          (it) => it.localId !== action.payload.localId
        ),
        action.payload,
      ];
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(setOnline, (state, action) => {
      state.isOffline = !action.payload;
      return state;
    });
  },
});

export const { addPendingAction } = networkStatusSlice.actions;

export const networkStatusSelector = (state: RootState) => state.networkStatus;

export default networkStatusSlice.reducer;
