import { Route } from "react-router-dom";
import { currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import ErrorBoundaryRoutes from "../../shared/error-boundary-routes";
import PageNotFound from "../../shared/page-not-found";
import BadgeListPage from "../user/badge-list.page";
import BadgeCreatePage from "./badge-create.page";

const BadgeRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isProfessionista = currentUser?.authorityChecks.isProfessionista;

  return (
    <ErrorBoundaryRoutes>
      {currentUser &&
        (isSportellista || isProfessionista || isAdministrator) && (
          <>
            <Route path="" element={<BadgeListPage isAdminPage />} />
            <Route path="create" element={<BadgeCreatePage />} />
            {isAdministrator && (
              <Route path=":id/edit" element={<BadgeCreatePage />} />
            )}
            {/* 
          <Route path=":id" element={<SurveyTemplateDetailPage />} />
          */}
          </>
        )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default BadgeRoutes;
