import {
  canExpand,
  descriptionId,
  getTemplate,
  getUiOptions,
  ObjectFieldTemplateProps,
  RJSFSchema,
  titleId,
} from "@rjsf/utils";

/** The LayoutFieldTemplate` is the template to use to render all the inner properties of an object along with the
 * title and description if available. If the object is expandable, then an `AddButton` is also rendered after all
 * the properties.
 *
 * @param props - The `LayoutFieldTemplateProps` for this component
 */
const LayoutFieldTemplate = (
  props: ObjectFieldTemplateProps<any, RJSFSchema>
) => {
  const {
    description,
    disabled,
    formData,
    idSchema,
    onAddClick,
    properties,
    readonly,
    registry,
    required,
    schema,
    title,
    uiSchema,
  } = props;

  const options = getUiOptions(uiSchema);
  const TitleFieldTemplate = getTemplate(
    "TitleFieldTemplate",
    registry,
    options
  );
  const DescriptionFieldTemplate = getTemplate(
    "DescriptionFieldTemplate",
    registry,
    options
  );
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;

  const cols =
    uiSchema && uiSchema["ui:options"] && uiSchema["ui:options"].cols;
  const card =
    uiSchema &&
    uiSchema["ui:options"] &&
    uiSchema["ui:options"].card !== undefined
      ? uiSchema["ui:options"].card
      : false;

  const getFields = () => (
    <fieldset id={idSchema.$id}>
      {title && (
        <TitleFieldTemplate
          id={titleId(idSchema)}
          title={title}
          required={required}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      {description && (
        <DescriptionFieldTemplate
          id={descriptionId(idSchema)}
          description={description}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      <div className="row">
        {properties.map((prop: any, index: any) => {
          const name = prop.name;
          const col =
            cols && (cols as any)[name] !== undefined
              ? (cols as any)[name]
              : 12;

          return (
            <div key={`L${index}`} className={"col-md-" + col}>
              {prop.content}
            </div>
          );
        })}
      </div>

      {canExpand(schema, uiSchema, formData) && (
        <AddButton
          className="object-property-expand"
          onClick={onAddClick(schema)}
          disabled={disabled || readonly}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
    </fieldset>
  );

  if (card) {
    return (
      <div
        className="card"
        style={{ boxShadow: "0 0 0 .25rem rgba(13, 110, 253, .25)" }}
      >
        <div className="card-body">{getFields()}</div>
      </div>
    );
  } else {
    return <>{getFields()}</>;
  }
};

export default LayoutFieldTemplate;
