import { Route } from 'react-router-dom';
import AppFooter from './navigation/footer';
import AppNavBar from './navigation/navbar';
import AppSideBar from './navigation/sidebar';
import CurrentUserView from './pages/user/current-user.view';
import PageRoutes from './pages/page.routes';
import { userAccessTokenSelector } from './redux/reducers/userSlice';
import { useAppSelector } from './redux/store';
import ErrorBoundaryRoutes from './shared/error-boundary-routes';

const AppRoutes = () => {
  const accessToken = useAppSelector(userAccessTokenSelector);
  return (
    <>
      <div id="app" style={{ display: 'flex', width: '100%' }}>
        {accessToken && false && <AppSideBar />}
        <main style={{ width: '100%', minHeight: '100vh' }}>
          <div style={{ minHeight: '100vh' }}>
            <AppNavBar></AppNavBar>
            <CurrentUserView></CurrentUserView>
            <ErrorBoundaryRoutes>
              <Route path="*" element={<PageRoutes />} />
            </ErrorBoundaryRoutes>
            <AppFooter />
          </div>
        </main>
      </div>
    </>
  );
};

export default AppRoutes;
