import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { getResetPasswordCodeConf } from "../../api/userAPI";
import useRequest from "../../hook/use-request.hook";
import { useAppUtilContext } from "../../util/app-util.context";
import { registerRs } from "../../util/form.util";

export default function ForgotPasswordPage() {
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading, fetchData } = useRequest();

  const onSubmit = (data: any) => {
    fetchData(getResetPasswordCodeConf(data), onSuccess, onErrors);
  };

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t("modal.request_success"), message, null);
    navigate("/");
  };

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t("modal.request_failed"), errorsView, null);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <div className="container">
      <Card>
        <CardBody>
          <CardTitle tag="h5">{t("user.forgot_password.title")}</CardTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="form-field">
              <Input
                disabled={loading}
                {...registerRs(register, "email", {
                  required: t(`general.errors.required`),
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: t(`general.errors.invalid_email`),
                  },
                })}
                invalid={errors.email && errors.email != null}
              />
              <Label className="form-label" for="email">
                {t("user.login.username")}
              </Label>
              <FormFeedback>{"" + errors?.email?.message}</FormFeedback>
            </FormGroup>
            {
              <Button type="submit" disabled={loading}>
                {!loading ? (
                  t("general.actions.send")
                ) : (
                  <>
                    {/* <Spinner size="sm">
                                    Loading...
                                </Spinner>
                                    <span>
                                        {' '}   {t('general.actions.loading')}
                                    </span> */}
                    <svg
                      viewBox="25 25 50 50"
                      className="svgcircle d-none d-md-inline mt-2"
                    >
                      <circle r="20" cy="50" cx="50"></circle>
                    </svg>
                  </>
                )}
              </Button>
            }
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
