import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { getDownloadFileConf } from "../api/fileAPI";
import useRequest from "../hook/use-request.hook";
import { useAppUtilContext } from "../util/app-util.context";
import { templateTableShowClass } from "../util/form.util";
import MobileButton from "./mobile-button";

interface DownloadButtonProps {
  inTable?: boolean;
  url: string;
  filename: string;
}
const DownloadButton = (props: DownloadButtonProps) => {
  const { inTable, url, filename } = props;

  const { loading, fetchData } = useRequest<any>();

  const { onErrorsModal } = useAppUtilContext();
  const [progress, setProgress] = useState(0);

  const onSuccessDownload = async (data: any) => {
    console.log("onSuccessDownload");
    // Convert the response to a Blob

    const blob = new Blob([data], { type: data.type });

    // Create a URL for the Blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename; // Set the file name here

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Clean up by removing the link and revoking the Blob URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  };
  const handleClick = async () => {
    setProgress(0);
    let conf = getDownloadFileConf(url);
    conf.onDownloadProgress = (e: any) => {
      console.log("onDownloadProgress", e);
      setProgress(e.progress * 100);
    };
    fetchData(conf, onSuccessDownload, onErrorsModal);
  };
  return (
    <>
      {!loading ? (
        <MobileButton
          inTable={inTable}
          icon={faDownload}
          className="d-block"
          color={templateTableShowClass}
          size="sm"
          onClick={handleClick}
        />
      ) : (
        <MobileButton
          inTable={inTable}
          className="d-block"
          color={templateTableShowClass}
          size="sm"
          disabled
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontSize: "17px", fontWeight: "bold" }}
            >{`${progress.toFixed(0)}%`}</span>
            <svg
              style={{ marginLeft: "3px" }}
              viewBox="0 0 30 30"
              className="svgcircle d-none d-md-inline "
            >
              <circle
                r="7"
                cy="15"
                cx="15"
                stroke="currentColor"
                strokeWidth="3"
                fill="none"
              />
            </svg>
          </div>
        </MobileButton>
      )}
    </>
  );
};

export default DownloadButton;
