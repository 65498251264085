import { AxiosRequestConfig } from "axios";
import { CreateBadge, DetailBadge } from "../model/badge.model";
import { defaultHeaders } from "./common";

export const getBadgeExportListConf = (query: string): AxiosRequestConfig => {
  return {
    method: "get",
    url: "/api/badge/export?" + query,
    headers: defaultHeaders,
    responseType: "arraybuffer",
  };
};
export const getBadgeListConf = (page: number, size: number, query: string) => {
  return {
    method: "get",
    url: "/api/badge?page=" + page + "&size=" + size + query,
    headers: defaultHeaders,
  };
};

export const getDetailBadgeConf = (id: string) => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/badge/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailBadgeConf = (badge: DetailBadge) => {
  return {
    method: "put",
    maxBodyLength: Infinity,
    url: `/api/badge/${badge.id}`,
    headers: defaultHeaders,
    data: JSON.stringify(badge),
  };
};

export const createDetailBadgeConf = (badge: CreateBadge) => {
  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/badge`,
    headers: defaultHeaders,
    data: JSON.stringify(badge),
  };
};
