import { useEffect, useState } from "react";
import Select from "react-select";
import { Form, FormGroup, Label } from "reactstrap";
import { getAllUserListConf } from "../api/userAPI";
import useRequest from "../hook/use-request.hook";
import { useAppUtilContext } from "../util/app-util.context";

interface SelectUserViewProps {
  readonly?: boolean;
  userId?: number;
  handleChange: (userId?: number) => void;
}

const SelectUserView = (props: SelectUserViewProps) => {
  const { userId, handleChange } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState<any[]>([]);

  const { onErrorsModal } = useAppUtilContext();
  const { loading, fetchData } = useRequest();
  useEffect(() => {}, [selectedOption]);

  useEffect(() => {
    if (options) {
      let option = options.find((it: any) => it.value === userId);

      setSelectedOption(option);
    }
  }, [userId, options]);
  useEffect(() => {
    refresh({ page: 0, pageSize: 10 });
  }, []);
  const refresh = (pag: any) => {
    let query = "";

    fetchData(
      getAllUserListConf(),
      (data: any, header: any) => {
        let optionsTemp = data.map((it: any) => {
          let label = it.firstName + " " + it.lastName;
          if (it.fiscalCode) {
            label += " (" + it.fiscalCode + ")";
          }

          return {
            value: it.id,
            label,
          };
        });
        setOptions(optionsTemp);
      },
      onErrorsModal
    );
  };
  // Handle select change
  const handleSelectionChange = (selected: any) => {
    console.log(selected);
    handleChange(selected.value);
  };

  return (
    <Form>
      <FormGroup>
        <Label for="searchableSelect">Select a User</Label>
        <Select
          id="searchableSelect"
          value={selectedOption}
          isLoading={loading}
          onChange={handleSelectionChange}
          options={options} // Pass options
          isSearchable // Enable search
          placeholder="Search or select..."
          isDisabled={props.readonly}
        />
      </FormGroup>
    </Form>
  );
};

export default SelectUserView;
