import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./style/bootstrap.scss";
import { AppUtilContextProvider } from "./util/app-util.context";
import AppRoutes from "./routes";
import { useAppSelector } from "./redux/store";
import { userLngSelector } from "./redux/reducers/userSlice";
import { useEffect } from "react";
import i18n from "./i18n";

function App() {
  const lang = useAppSelector(userLngSelector);

  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <AppUtilContextProvider>
      <BrowserRouter basename="/">
        <AppRoutes />
      </BrowserRouter>
    </AppUtilContextProvider>
  );
}

export default App;
