import { faEye, faRefresh, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, FormGroup, Input, Table } from "reactstrap";
import { getArchievedSurveyAnswerListConf } from "../../api/archivedSurveyAnswerAPI";
import { getSurveyTemplateLookupListConf } from "../../api/surveyTemplateAPI";
import BreadcrumbsUtility from "../../components/breadcrumb";
import { ErrorsView } from "../../components/errors.view";
import MobileButton from "../../components/mobile-button";
import PaginationUtility from "../../components/pagination";
import useRequest from "../../hook/use-request.hook";
import {
  currentUserSelector,
  userLngSelector,
} from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { useAppUtilContext } from "../../util/app-util.context";
import { templateTableShowClass } from "../../util/form.util";
import { isStringified } from "../survey-template/survey-template-edit.page";

export default function SurveyAnswerDetailListPage() {
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<any[]>([]);
  let { id } = useParams();
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const lang = useAppSelector(userLngSelector);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const onErrors = (errors: any) => {
    showModal(
      t("modal.request_errors"),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  useEffect(() => {
    setList(
      list.map((el: any) => {
        let title = isStringified(el.stTitle);
        return { ...el, ...{ title: title[lang], titleFull: title } };
      })
    );
  }, [lang]);

  const convertTitle = (title: any) => {
    let stringedTitle = isStringified(title);
    return stringedTitle[lang];
  };

  const handleChange = (e: any) => {
    if (e.target.id === "fromDate") setFromDate(e.target.value);
    else if (e.target.id === "toDate") setToDate(e.target.value);
  };

  const refreshFirstPage = () => {
    refresh({
      page: 1,
      pageSize: 21,
      totalElements: null,
    });
  };
  useEffect(() => {
    refreshFirstPage();
    refreshTemplates();
  }, []);

  useEffect(() => {
    // refresh(pagination);
  }, [pagination]);

  const refreshTemplates = () => {
    fetchData(
      getSurveyTemplateLookupListConf(),
      (data: any, header: any) => {
        let templates = data.map((el: any) => {
          let title = JSON.parse(el.title);

          return {
            id: el.id,
            title: title,
          };
        });
      },
      () => {}
    );
  };

  const refresh = (pag: any) => {
    let query = "";
    // if (status) query = '&status=' + status;
    if (fromDate) query = query + "&fromDate=" + fromDate;
    if (toDate) query = query + "&toDate=" + toDate;
    // if (templateId && templateId !== '0')  query = query + '&surveyTemplateId=' + templateId;

    fetchData(
      getArchievedSurveyAnswerListConf(pag.page, pag.pageSize, query, id),
      (data: any, header: any) => {
        setList(
          data.map((el: any) => {
            let title = isStringified(el.templateTitle);
            return { ...el, ...{ title: title[lang], titleFull: title } };
          })
        );
        setPagination({
          ...pag,
          ...{ totalElements: header["x-total-count"] },
        });
      },
      onErrors
    );
  };

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[{ text: t("archive-survey-answer.list.title") }]}
      />
      <div className="header-container">
        <h2 className="page-title">{t("archive-survey-answer.list.title")}</h2>
      </div>
      <div className="filter-card card mb-2">
        <div className="card-body">
          <h6 className="mt-1 mb-3">{t("general.actions.filter")}</h6>
          <div className="mt-2">
            <div className="row"></div>
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-5">
                <FormGroup>
                  <div
                    style={{ marginBottom: ".5rem", display: "inline-block" }}
                  >
                    {t("general.date.from")}
                  </div>
                  <Input
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5">
                <FormGroup>
                  <div
                    style={{ marginBottom: ".5rem", display: "inline-block" }}
                  >
                    {t("general.date.to")}
                  </div>
                  <Input
                    id="toDate"
                    name="toDate"
                    type="date"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>

              <div className="col-lg- col-md-2 col-sm-2">
                <div style={{ marginBottom: ".5rem" }}>&nbsp;</div>
                <Button
                  type="submit"
                  color="primary"
                  onClick={refreshFirstPage}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="justify-content-end pt-3"
        style={{ display: "flex", gap: "10px" }}
      >
        <MobileButton
          onClick={() => refresh(pagination)}
          disabled={loading}
          color="secondary"
          outline
        >
          {!loading ? (
            <>
              <FontAwesomeIcon icon={faRefresh} />
              <span className="d-none d-md-inline">
                &nbsp; {t("general.buttons.refresh")}
              </span>
            </>
          ) : (
            <>
              <svg
                viewBox="25 25 50 50"
                className="svgcircle d-none d-md-inline mt-2"
              >
                <circle r="20" cy="50" cx="50"></circle>
              </svg>
            </>
          )}
        </MobileButton>
      </div>
      {pagination.totalElements == 0 && (
        <div className="pb-3 pt-3 fs-5">
          {t("general.errors.no_items_found")}
        </div>
      )}
      {pagination.totalElements > 0 && !loading && (
        <div className="mt-2 row">
          <Table striped responsive>
            <thead>
              <tr style={{ whiteSpace: "break-spaces" }}>
                <th style={{ width: "10%" }}>{t("general.id")}</th>{" "}
                <th style={{ width: "10%" }}>{t("Titolo Questionario")}</th>
                <th style={{ width: "10%" }}>{t("Data")}</th>
              </tr>
              <tr style={{ whiteSpace: "break-spaces" }}></tr>
            </thead>
            <tbody>
              {list.map((it, idx) => {
                return (
                  <tr key={it.id}>
                    <td style={{ width: "33%" }}>{it.id}</td>
                    <td style={{ width: "33%" }}>
                      {convertTitle(it.templateTitle)}
                    </td>

                    <td style={{ width: "33%" }}>{it.date}</td>
                    <td
                      style={{
                        whiteSpace: "break-spaces",
                        textAlign: "center",
                      }}
                    >
                      <ButtonGroup>
                        <MobileButton
                          inTable={true}
                          icon={faEye}
                          className="d-block"
                          color={templateTableShowClass}
                          size="sm"
                          id={"view" + it.saId}
                          onClick={() => navigate(`${it.id}`)}
                        />
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <div className="pb-5">
        <PaginationUtility
          {...pagination}
          onSizeChange={(n: any) => {
            if (pagination.pageSize !== n)
              refresh({ ...pagination, ...{ page: 1, pageSize: n } });
          }}
          onChange={(n: any) => {
            if (pagination.page !== n)
              refresh({ ...pagination, ...{ page: n } });
          }}
        />
      </div>
    </div>
  );
}
