import { useAppUtilContext } from "../util/app-util.context";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import MobileButton from "./mobile-button";
import { deleteEntityConf } from "../api/common";
import useRequest from "../hook/use-request.hook";

const DeleteButton = (props: any) => {
  const id = props.id
  const entityType = props.entityType
  const refresh = props.refresh
  const { onErrorsModal, onDelete, closeModal } = useAppUtilContext();
  const { fetchData } = useRequest();
  
    return (
      <MobileButton
          inTable={true}
          icon={faTrash}
          className="d-block"
          color={"danger"}
          size="sm"
          id={"view" + id}
          onClick={() =>
            onDelete(() => {
              fetchData(
                deleteEntityConf(entityType, id),
                (data: any, header: any) => {
                  refresh()
                  closeModal()
                },
                onErrorsModal
              );
            })
          }
        />
    );
};

export default DeleteButton;
