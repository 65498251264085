import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { getSigninConf } from "../../api/userAPI";
import useRequest from "../../hook/use-request.hook";
import { signinSuccess } from "../../redux/reducers/userSlice";
import { AppThunkDispatch } from "../../redux/store";
import { useAppUtilContext } from "../../util/app-util.context";
import {
  FormWithBootstrap,
  templates,
  transformErrors,
} from "../../util/form-util";

export default function LoginPage() {
  const dispatch = useDispatch() as AppThunkDispatch;
  const { showModal } = useAppUtilContext();
  const { t } = useTranslation();
  const { loading, fetchData } = useRequest();
  const navigate = useNavigate();
  const onSuccess = (data: any) => {
    dispatch(signinSuccess(data));
    navigate("/");
  };

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t("modal.request_failed"), errorsView, null);
  };

  const onFormSubmit = ({ formData }: any) => {
    const { email, password } = formData;
    fetchData(getSigninConf({ email, password }), onSuccess, onErrors);
  };
  const schema: RJSFSchema = {
    title: t("user.login.title"),
    type: "object",
    required: ["email", "password"],
    properties: {
      email: {
        type: "string",
        title: t("user.login.username"),
        format: "email",
      },
      password: {
        type: "string",
        title: t("user.login.password"),
      },
    },
  };
  const uiSchema = {
    password: {
      "ui:widget": "password",
    },
  };

  let datas: any = {};
  return (
    <div className="container mt-4 col-lg-4">
      <Card>
        <CardBody>
          <FormWithBootstrap
            templates={templates}
            onSubmit={onFormSubmit}
            formData={datas}
            schema={schema}
            uiSchema={uiSchema}
            validator={validator}
            transformErrors={transformErrors}
            showErrorList={false}
            readonly={loading}
          ></FormWithBootstrap>
          <hr></hr>
          <p>
            <Link to="/user/forgot-password">
              {t("user.forgot_password.title")}
            </Link>
          </p>
        </CardBody>
      </Card>
    </div>
  );
}
