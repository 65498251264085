import { Route } from "react-router-dom";
import { currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import ErrorBoundaryRoutes from "../../shared/error-boundary-routes";
import PageNotFound from "../../shared/page-not-found";
import RealEstateAgencyDetailPage from "./real-estate-agency-detail.page";
import RealEstateAgencyListPage from "./real-estate-agency-list.page";

const RealEstateAgencyRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isProfessionista = currentUser?.authorityChecks.isProfessionista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;

  return (
    <ErrorBoundaryRoutes>
      {currentUser &&
        (isSportellista ||
          isAdministrator ||
          isOperatore) && (
          <>
            <Route path="" element={<RealEstateAgencyListPage />} />
            {!isOperatore && (
              <Route
                path="/create"
                element={<RealEstateAgencyDetailPage edit />}
              />
            )}
            <Route path=":id" element={<RealEstateAgencyDetailPage />} />
            {!isOperatore && (
              <Route
                path=":id/edit"
                element={<RealEstateAgencyDetailPage edit />}
              />
            )}
          </>
        )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default RealEstateAgencyRoutes;
