import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import FullCalendar from "@fullcalendar/react";
import { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { userLngSelector } from "../redux/reducers/userSlice";
import { useAppSelector } from "../redux/store";
import { formattaData } from "../util/form.util";

interface CalendarProps {
  data: any[];
  updateMonth: (dateInfo: any) => void;
  updateDate: (dateInfo: any) => void;
  isCompressed: boolean;
}

function findMonthBeetween(date1: any, date2: any) {
  // Convertire le date in timestamp (millisecondi)
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();
  const midpointTime = (time1 + time2) / 2;
  const midpointDate = new Date(midpointTime);

  let giorno = new Date(midpointDate.getFullYear(), midpointDate.getMonth(), 1);

  return giorno;
}

export default function Calendar(props: CalendarProps) {
  const { data, isCompressed, updateDate, updateMonth } = props;
  const lang = useAppSelector(userLngSelector);
  const [eventCounts, setEventCounts] = useState<any>({});
  const [eventGroup, setEventGroup] = useState<any>({});

  console.log("data", data);
  useEffect(() => {
    const counts: any = {};
    const groups: any = {};
    data.forEach((event) => {
      const dayKey = formattaData(event.start);

      counts[dayKey] = counts[dayKey] ? counts[dayKey] + 1 : 1;
      groups[dayKey] = groups[dayKey]
        ? [...groups[dayKey], ...[event]]
        : [event];
    });
    setEventCounts(counts);
    setEventGroup(groups);
  }, [data]);

  const handleDatesSet = (dateInfo: any) => {
    updateMonth(findMonthBeetween(dateInfo.startStr, dateInfo.endStr));
  };

  const renderDayCell = (args: any) => {
    const dayKey = formattaData(args.date);
    const eventCount = eventCounts[dayKey] || 0;

    return eventCount > 0 ? "full" : "";
  };

  const dayCellContent = (args: any) => {
    const dayKey = formattaData(args.date);
    const eventCount = eventCounts[dayKey] || 0;

    return (
      <>
        {eventCount > 0 && (
          <Badge style={{ marginRight: "10px" }} color="dark">
            {eventCount}
          </Badge>
        )}
        <span>{args.dayNumberText}</span>
      </>
    );
  };

  return (
    <div style={isCompressed ? { width: "400px" } : {}}>
      <FullCalendar
        headerToolbar={{
          left: "prev",
          center: "title",
          right: "next",
        }}
        themeSystem="bootstrap"
        viewClassNames={isCompressed ? "compressed-calendar" : ""}
        plugins={[dayGridPlugin]}
        datesSet={handleDatesSet}
        initialView="dayGridMonth"
        events={data}
        locale={lang}
        dayCellClassNames={renderDayCell}
        dayCellContent={dayCellContent}
        navLinks={true}
        navLinkDayClick={(date, jsEvent) => {
          updateDate(date);
        }}
      />
    </div>
  );
}
