import { Link } from "react-router-dom";
import { currentUserSelector } from "../redux/reducers/userSlice";
import { useAppSelector } from "../redux/store";

export default function AppFooter() {
  const currentUser = useAppSelector(currentUserSelector);

  return (
    <>
      <footer id="apus-footer" className="apus-footer" role="contentinfo">
        <div className="container-lg" style={{ textAlign: "center" }}>
          <hr />
          <img src="/gestim.png" alt="Gestim" />
          <br />
          {!currentUser && <Link to={"/user/login"}>Login</Link>}
        </div>
      </footer>
    </>
  );
}
