import {
  Authorities,
  CurrentUser,
  currentUserSelector,
} from "../redux/reducers/userSlice";
import { useAppSelector } from "../redux/store";

export function hasCommonElement(arr1: any[], arr2: any[]) {
  return arr1.some((element) => arr2.includes(element));
}

export const checkRequiredAuthorities = (
  permittedAuthorities: Authorities[],
  currentUser: CurrentUser | null
) => {
  if (permittedAuthorities.length === 0) {
    return true;
  }
  const currentUserAuthorities = currentUser?.authorities
    ? currentUser?.authorities
    : [];

  let permitted = hasCommonElement(
    currentUserAuthorities,
    permittedAuthorities
  );
  return permitted;
};

interface AuthorityBoundaryViewProps {
  children: any;
  permittedAuthorities: Authorities[];
}

function AuthorityBoundaryView(props: AuthorityBoundaryViewProps) {
  const { permittedAuthorities, children } = props;
  const currentUser = useAppSelector(currentUserSelector);
  const currentUserAuthorities = currentUser?.authorities
    ? currentUser?.authorities
    : [];

  let permitted = hasCommonElement(
    currentUserAuthorities,
    permittedAuthorities
  );
  return <>{permitted ? children : null}</>;
}

export default AuthorityBoundaryView;
