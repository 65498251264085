import { UseFormRegisterReturn } from "react-hook-form";

export const registerRs = (register: any, fieldName: string, options = {}) => {
  const registeredField: Partial<UseFormRegisterReturn> = register(
    fieldName,
    options
  );
  const ref = registeredField.ref;
  delete registeredField.ref;
  return { ...registeredField, innerRef: ref };
};

export const templateGoToActionClass = "secondary";
export const templateTableEditClass = "dark";
export const templateTableShowClass = "outline-dark";

const onSuccessMessage = (data: any, showModal: any, t: any) => {
  let message = data.message;
  showModal(t("modal.request_success"), message, null);
};
const onErrorMessage = (data: any, showModal: any, t: any) => {
  let message = data.message;
  showModal(t("modal.request_success"), message, null);
};

export const getDateYYYYMMDD = (date: Date) => {
  if (date !== null) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
};

export function formattaFullData(data: any, t: any) {
  if (data !== null) {
    let d = typeof data == "string" ? new Date(data) : data;

    const lastBadge: any = formattaReadableData(d, t);
    const formatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedTime = formatter.format(new Date(d));

    return (
      lastBadge?.date +
      " " +
      lastBadge?.month +
      " " +
      lastBadge?.year +
      " - " +
      formattedTime
    );
  }
}

export function formattaData(data: any) {
  if (data !== null) {
    let d = typeof data == "string" ? new Date(data) : data;

    let anno = d.getFullYear();
    let mese = d.getMonth() + 1;
    let giorno = d.getDate();

    mese = mese < 10 ? "0" + mese : mese;
    giorno = giorno < 10 ? "0" + giorno : giorno;
    return `${anno}-${mese}-${giorno}`;
  }
  return "---";
}
export function formattaReadableData(data: any, t: any) {
  let d = typeof data == "string" ? new Date(data) : data;

  let mese = d.getMonth() + 1;
  let giorno = d.getDate();
  let year = d.getFullYear();

  // mese = mese < 10 ? "0" + mese : mese;
  giorno = giorno < 10 ? "0" + giorno : giorno;
  return {
    date: giorno,
    month: t("general.date.mesi." + mese),
    year: year,
  };
}
export function formattaReadableDataMonth(start: any, end: any, t: any) {
  let s = formattaReadableData(start, t);
  let e = formattaReadableData(end, t);
  return {
    start: s.date + " " + s.month,
    end: e.date + " " + e.month,
  };
}
export function formattaDateTimeToSave(d: any) {
  let data = d.split("T");

  return data[0] + " " + data[1].slice(0, 5);
}
