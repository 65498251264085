import {
  CreateUser,
  ResetPasswordCodeReq,
  ResetPasswordReq,
  SigninReq,
  SignupReq,
  UpdateReq,
} from "../model/user.model";
import { defaultHeaders } from "./common";

export const getSigninConf = (signinReq: SigninReq) => {
  return {
    method: "post",
    url: "/api/auth/signin",
    headers: defaultHeaders,
    data: JSON.stringify(signinReq),
  };
};

export const createUserInfo = (user: CreateUser) => {
  return {
    method: "post",
    url: "/api/user",
    headers: defaultHeaders,
    data: user,
  };
};

export const updateUserInfo = (signinReq: UpdateReq) => {
  return {
    method: "put",
    url: "/api/user/" + signinReq.id,
    headers: defaultHeaders,
    data: JSON.stringify(signinReq),
  };
};

export const geCurrentUserConf = () => {
  return {
    method: "get",
    url: "/api/auth/current-user",
    headers: defaultHeaders,
  };
};

export const getUserCount = () => {
  return {
    method: "get",
    url: "/api/auth/count",
    headers: defaultHeaders,
  };
};

export const getResetPasswordConf = (resetPasswordReq: ResetPasswordReq) => {
  let data = JSON.stringify(resetPasswordReq);
  return {
    method: "post",
    url: "/api/auth/reset-password",
    headers: defaultHeaders,
    data: data,
  };
};

export const getResetPasswordCodeConf = (
  resetPasswordCodeReq: ResetPasswordCodeReq
) => {
  let data = JSON.stringify(resetPasswordCodeReq);
  return {
    method: "post",
    url: "/api/auth/reset-password-code",
    headers: defaultHeaders,
    data: data,
  };
};

export const getSignupConf = (signupReq: SignupReq) => {
  return {
    method: "post",
    url: "/api/auth/signup",
    headers: defaultHeaders,
    data: JSON.stringify(signupReq),
  };
};

export const getActivateConf = (code: string) => {
  let data = JSON.stringify({ code: code });
  return {
    method: "post",
    url: "/api/auth/activate",
    headers: defaultHeaders,
    data: data,
  };
};

export const getAllUserListConf = () => {
  return {
    method: "get",
    url: "/api/user",
    headers: defaultHeaders,
  };
};
export const getUserListConf = (page: number, size: number, query: string) => {
  return {
    method: "get",
    url: "/api/user?page=" + page + "&size=" + size + query,
    headers: defaultHeaders,
  };
};

const getCurrentDateTime = () => {
  const currentDateTime = new Date();
  const year = currentDateTime.getFullYear();
  const month = String(currentDateTime.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(currentDateTime.getDate()).padStart(2, "0");
  const hours = String(currentDateTime.getHours()).padStart(2, "0");
  const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");
  const seconds = String(currentDateTime.getSeconds()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  console.log(formattedDateTime);
  return formattedDateTime;
};

export const getBadgeInOutConf = (isIn: boolean) => {
  return {
    method: "post",
    url: "/api/user/badge",
    headers: defaultHeaders,
    data: isIn
      ? {
          type: "IN",
          date: getCurrentDateTime(),
        }
      : {
          type: "OUT",
          date: getCurrentDateTime(),
        },
  };
};
export const getBadgeInOutListConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: "get",
    url: "/api/user/badge?page=" + page + "&size=" + size + query,
    headers: defaultHeaders,
  };
};
export const changeEnableUserConf = (id: string, enable: boolean) => {
  return {
    method: "put",
    url: "/api/user/" + id + "/enable",
    headers: defaultHeaders,
    data: { id: parseInt(id), enable },
  };
};

export const changeActivateUserConf = (email: string) => {
  return {
    method: "put",
    url: "/api/admin/activate-user",
    headers: defaultHeaders,
    data: { email },
  };
};

export const getDetailUserConf = (id: string) => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/user/${id}`,
    headers: defaultHeaders,
  };
};
