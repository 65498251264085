import {
  faCheck,
  faCheckDouble,
  faClipboardQuestion,
  faEye,
  faListCheck,
  faPencil,
  faRefresh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Table,
} from "reactstrap";
import { getSurveyAnswerListConf } from "../../api/surveyAnswerAPI";
import {
  getSurveyTemplateEnabledListConf,
  getSurveyTemplateLookupListConf,
} from "../../api/surveyTemplateAPI";
import MobileButton from "../../components/mobile-button";
import PaginationUtility from "../../components/pagination";
import useRequest from "../../hook/use-request.hook";
import { LookupSurveyAnswer } from "../../model/survey-answer.model";
import { LookupSurveyTemplate } from "../../model/survey-template.model";
import {
  currentUserSelector,
  userLngSelector,
} from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { useAppUtilContext } from "../../util/app-util.context";
import {
  templateGoToActionClass,
  templateTableEditClass,
  templateTableShowClass,
} from "../../util/form.util";
import { isStringified } from "../survey-template/survey-template-edit.page";
import DeleteButton from "../../components/delete-button";

export default function HomeSurveyAnswerListPage(props: any) {
  const { onErrorsModal } = useAppUtilContext();
  const currentUser = useAppSelector(currentUserSelector);
  const { withLink } = props;
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [templateList, setTemplateList] = useState<LookupSurveyTemplate[]>([]);
  const [list, setList] = useState<LookupSurveyAnswer[]>([]);
  const [cf, setCf] = useState<any>(null);
  const [protNumber, setProtNumber] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [templateId, setTemplateId] = useState<any>(null);
  const lang = useAppSelector(userLngSelector);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const refreshTemplatess = () => {
    fetchData(
      getSurveyTemplateEnabledListConf(),
      (data: any, header: any) => {
        setTemplateList(
          data.map((el: any) => {
            let title = isStringified(el.title);
            return { ...el, ...{ title: title[lang], titleFull: title } };
          })
        );
      },
      () => {}
    );
  };

  useEffect(() => {
    if (currentUser !== null) refreshTemplatess();
  }, [currentUser]);

  useEffect(() => {
    setList(
      list.map((el: any) => {
        let title = isStringified(el.stTitle);
        return { ...el, ...{ title: title[lang], titleFull: title } };
      })
    );
    setTemplateList(
      templateList.map((el: any) => {
        return { ...el, ...{ title: el.titleFull[lang] } };
      })
    );
  }, [lang]);

  const handleChange = (e: any) => {
    if (e.target.id === "fromDate") setFromDate(e.target.value);
    else if (e.target.id === "toDate") setToDate(e.target.value);
    else if (e.target.id === "fiscalCode") setCf(e.target.value);
    else if (e.target.id === "protNumber") setProtNumber(e.target.value);
  };

  const handleTemplateChange = (e: any) => {
    setTemplateId(e.target.value);
  };

  const refreshFirstPage = () => {
    setPagination({
      page: 1,
      pageSize: 21,
      totalElements: null,
    });

    refresh(pagination);
  };
  useEffect(() => {
    refreshFirstPage();
    refreshTemplates();
    refreshTemplatess();
  }, []);

  const refreshTemplates = () => {
    fetchData(
      getSurveyTemplateLookupListConf(),
      (data: any, header: any) => {
        setTemplateList(
          data.map((el: any) => {
            return { ...el, ...{ title: el.titleFull[lang] } };
          })
        );
      },
      () => {}
    );
  };

  const refresh = (pag: any) => {
    let query = "";
    if (status) query = "&status=" + status;
    if (cf) query = "&codiceFiscale=" + cf;
    if (protNumber) query = "&protNumber=" + protNumber;
    if (fromDate) query = query + "&fromDate=" + fromDate;
    if (toDate) query = query + "&toDate=" + toDate;
    if (templateId && templateId !== "0")
      query = query + "&surveyTemplateId=" + templateId;

    fetchData(
      getSurveyAnswerListConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(
          data.map((el: any) => {
            let title = isStringified(el.stTitle);
            return { ...el, ...{ title: title[lang], titleFull: title } };
          })
        );
        setPagination({
          ...pag,
          ...{ totalElements: header["x-total-count"] },
        });
      },
      onErrorsModal
    );
  };

  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;

  return (
    <div className="col-lg-6 col-md-6 col-sm-6">
      <div className="card">
        <div className="container">
          <div className="header-container">
            <h2 className="page-title">
              <FontAwesomeIcon size="2x" icon={faClipboardQuestion} />{" "}
              {t("home.survey-answer")}
              {withLink && (
                <Button
                  text="risposte"
                  onClick={() => navigate(`/survey-answer`)}
                  color={templateGoToActionClass}
                  style={{ float: "right", marginTop: "5px" }}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "1rem" }}
                    icon={faListCheck}
                  />{" "}
                  {t("home.survey-answer-all")}
                </Button>
              )}
            </h2>
          </div>
          <hr />
          {isAdministrator || isSportellista ? (
            <>
              {templateList &&
                templateList.length > 0 &&
                templateList.map((template: LookupSurveyTemplate) => (
                  <>
                    <MobileButton
                      outline
                      icon={faCheck}
                      color={"primary"}
                      text={template.title}
                      onClick={() =>
                        navigate(`/survey-template/${template.id}/answer`)
                      }
                    />{" "}
                  </>
                ))}
              {!currentUser && (
                <MobileButton
                  icon={faCheck}
                  color={"primary"}
                  size="lg"
                  text={t("misc.assistente")}
                />
              )}
            </>
          ) : (
            ""
          )}

          {isAdministrator || isSportellista ? (
            <>
              <div className="row" style={{ marginTop: "1rem" }}>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <FormGroup>
                    <Input
                      id="fiscalCode"
                      name="fiscalCode"
                      placeholder={t("home.user-cf")}
                      type="text"
                      onChange={handleChange}
                    ></Input>
                  </FormGroup>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <FormGroup>
                    <Input
                      id="protNumber"
                      name="protNumber"
                      placeholder={t("home.prot-number")}
                      type="text"
                      onChange={handleChange}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <FormGroup>
                    <Input
                      id="templateId"
                      name="templateId"
                      type="select"
                      onChange={handleTemplateChange}
                    >
                      <option key={0} value={0}>
                        {t("home.survey-template-name")}
                      </option>
                      {templateList.map((it) => {
                        return (
                          <option key={it.id} value={it.id}>
                            {it.title}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </div>
              </div>
              <div className="row" style={{ marginTop: "1rem" }}>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <FormGroup>
                    <div
                      style={{ marginBottom: ".5rem", display: "inline-block" }}
                    >
                      {t("general.date.from")}
                    </div>
                    <Input
                      id="fromDate"
                      name="fromDate"
                      type="date"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <FormGroup>
                    <div
                      style={{ marginBottom: ".5rem", display: "inline-block" }}
                    >
                      {t("general.date.to")}
                    </div>
                    <Input
                      id="toDate"
                      name="toDate"
                      type="date"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <ButtonGroup
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <MobileButton
                    color="primary"
                    icon={faSearch}
                    text={t("general.actions.search")}
                    onClick={refreshFirstPage}
                  ></MobileButton>
                  <MobileButton
                    onClick={() => refresh(pagination)}
                    disabled={loading}
                    color="secondary"
                    outline
                  >
                    {!loading ? (
                      <>
                        <FontAwesomeIcon icon={faRefresh} />
                      </>
                    ) : (
                      <>
                        {/* <Spinner size="sm">Loading...</Spinner> */}
                        <svg
                          viewBox="25 25 50 50"
                          className="svgcircle d-none d-md-inline mt-2"
                        >
                          <circle r="20" cy="50" cx="50"></circle>
                        </svg>
                      </>
                    )}
                  </MobileButton>
                  
                </ButtonGroup>
              </div>
            </>
          ) : (
            ""
          )}
          {pagination.totalElements == 0 && (
            <div className="pb-3 pt-3 fs-5">
              {t("general.errors.no_items_found")}
            </div>
          )}
          {pagination.totalElements > 0 && !loading && (
            <div className="mt-2 row">
              <Table striped responsive>
                <thead>
                  <tr style={{ whiteSpace: "break-spaces" }}>
                    <th>{t("general.id")}</th>{" "}
                    <th>{t("survey-answer.user")}</th>
                    <th>{t("survey-answer.surveyTemplate")}</th>
                    <th>{t("survey-answer.status")}</th>
                    <th style={{ width: "215px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((it, idx) => {
                    return (
                      <tr key={it.saId}>
                        <td style={{ width: "auto" }}>{it.saId}</td>
                        <td style={{ width: "auto" }}>
                          {it.uId ? (
                            <a
                              href=""
                              onClick={() => navigate(`/user/${it.uId}`)}
                            >
                              {it.uFullName}
                            </a>
                          ) : (
                            it.uFullName
                          )}
                        </td>
                        <td style={{ width: "auto" }}>{it.title}</td>
                        <td>
                          {it.saVerificationStatus == null ||
                          it.saVerificationStatus === "NOT_VERIFIED" ? (
                            <Badge color="secondary">
                              {t("survey-answer.status-NOT_VERIFIED")}
                            </Badge>
                          ) : it.saVerificationStatus === "SENT" ? (
                            <Badge color="warning">
                              {t("survey-answer.status-SENT")}
                            </Badge>
                          ) : (
                            <Badge color="success">
                              {t("survey-answer.status-VERIFIED")}
                            </Badge>
                          )}
                        </td>
                        <td>
                          <ButtonGroup>
                            <MobileButton
                              inTable={true}
                              icon={faEye}
                              className="d-block"
                              color={templateTableShowClass}
                              size="sm"
                              id={"view" + it.saId}
                              onClick={() =>
                                navigate(`/survey-answer/${it.saId}`)
                              }
                            />
                            {!isOperatore && (
                              <MobileButton
                                inTable={true}
                                icon={faPencil}
                                className="d-block"
                                color={templateTableEditClass}
                                size="sm"
                                id={"view" + it.saId}
                                onClick={() =>
                                  navigate(`/survey-answer/${it.saId}/edit`)
                                }
                              />
                            )}
                            <MobileButton
                              inTable={true}
                              icon={faCheckDouble}
                              className="d-block"
                              color={"success"}
                              size="sm"
                              text={t("general.buttons.verify")}
                              id={"view" + it.saId}
                              onClick={() =>
                                navigate(`/survey-answer/${it.saId}/SMSconfirm`)
                              }
                              disabled={it.saVerificationStatus == "VERIFIED"}
                              outline={it.saVerificationStatus == "VERIFIED"}
                            />
         { isAdministrator && <DeleteButton entityType='survey-answer' id={it.saId} refresh={() => { refresh(pagination) }} /> }

                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}

          <div className="pb-5">
            <PaginationUtility
              {...pagination}
              onSizeChange={(n: any) => {
                if (pagination.pageSize !== n)
                  refresh({ ...pagination, ...{ page: 1, pageSize: n } });
              }}
              onChange={(n: any) => {
                if (pagination.page !== n)
                  refresh({ ...pagination, ...{ page: n } });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
