import {
  faPencil,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Table,
} from "reactstrap";
import {
  createDetailAccomodationImageConf,
  deleteAccomodationImageConf,
  getAccomodationImageListConf,
  updateDetailAccomodationImageConf,
} from "../../api/accomodationImageAPI";
import DownloadButton from "../../components/DownloadButton";
import MobileButton from "../../components/mobile-button";
import useRequest from "../../hook/use-request.hook";
import { useAppUtilContext } from "../../util/app-util.context";
import { FormWithBootstrap, templates } from "../../util/form-util";
import {
  formattaDateTimeToSave,
  formattaFullData,
  templateTableEditClass,
} from "../../util/form.util";
import { ImageWidget } from "../../components/TemplateFieldShifter";
import { currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import DeleteButton from "../../components/delete-button";

export default function AddAccomodationImages() {
  let { id } = useParams();
  const formRef = useRef<any>(null);
  const [images, setImages] = useState<any>();
  const { loading, fetchData } = useRequest<any>();
  const [entity, setEntity] = useState<any>();
  const { t } = useTranslation();
  const currentUser = useAppSelector(currentUserSelector);
  const isOperatore = currentUser?.authorityChecks.isOperatore;
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;

  const currentFileRef = useRef<File>();
  const [showProg, setShowProg] = useState<any>({
    display: false,
    body: { progress: 0 },
  });
  useEffect(() => {
    if (id) {
      fetchData(
        getAccomodationImageListConf("?accomodationId=" + id),
        onSuccessDetail,
        onErrorsModal
      );
    }
  }, [id]);

  const { onSuccessModal, onErrorsModal, showModal, closeModal } =
    useAppUtilContext();

  const onSuccessDetail = (dto: any) => {
    setImages(dto);
    closeModal();
  };

  const createImage = () => {
    setEntity({ accomodationId: parseInt(id ? id : "0") });
  };

  const onChange = (props: any) => {
    // setEntity(formData);
  };

  const onSubmit = ({ formData }: any) => {
    let d = formattaDateTimeToSave(formData.date);
    closeModal();
    if (formData.id) onImageEdit({ ...formData, ...{ date: d } });
    else onImageCreate({ ...formData, ...{ date: d } });
  };

  const onSuccess = (props: any) => {
    fetchData(
      getAccomodationImageListConf("?accomodationId=" + id),
      onSuccessDetail,
      onErrorsModal
    );
  };
  const onFileChange = (file: File) => {
    currentFileRef.current = file;
  };
  const widgets = {
    "data-url": (props: any) => {
      return <ImageWidget {...props} onFileChange={onFileChange}></ImageWidget>;
    },
  };
  useEffect(() => {
    if (entity) {
      const schema: RJSFSchema = !entity.id
        ? {
            title: "",
            type: "object",
            properties: {
              imageId: {
                type: "number",
                minimum: 0,
                readOnly: true,
              },
              accomodationId: {
                type: "number",
                minimum: 0,
                title: t("accomodation.image.accomodationId"),
                readOnly: true,
              },
              date: {
                type: "string",
                title: t("accomodation.image.date"),
                format: "date-time",
              },
              imageData: {
                type: "string",
                title: t("accomodation.image.imageData"),
                format: "data-url",
              },
            },
            required: ["imageData", "date"],
          }
        : {
            title: "",
            type: "object",
            properties: {
              imageId: {
                type: "number",
                minimum: 0,
                readOnly: true,
              },
              accomodationId: {
                type: "number",
                minimum: 0,
                title: t("accomodation.image.accomodationId"),
                readOnly: true,
              },
              date: {
                type: "string",
                title: t("accomodation.image.date"),
                format: "date-time",
              },
            },
            required: ["date"],
          };
      const uiSchema = {
        imageId: { "ui:widget": "hidden" },
        accomodationId: { "ui:widget": "hidden" },
      };

      showModal(
        entity.id ? t("accomodation.image.edit") : t("accomodation.image.add"),
        <>
          <FormWithBootstrap
            formData={entity}
            ref={formRef}
            schema={schema}
            uiSchema={uiSchema}
            onChange={onChange}
            onSubmit={onSubmit}
            templates={templates}
            widgets={widgets}
            validator={validator}
            children={true}
          />{" "}
        </>,
        null,
        <MobileButton
          inTable={true}
          icon={faSave}
          className="d-block"
          style={{ width: "100%" }}
          color={"primary"}
          text={t("general.buttons.send")}
          onClick={() => {
            const err = formRef?.current.validateForm();

            if (err) formRef?.current.submit();
          }}
        />
      );
    }
  }, [entity]);

  const onImageCreate = (formData: any) => {
    let conf = createDetailAccomodationImageConf({
      ...formData,
      image: currentFileRef.current,
    });
    conf.onUploadProgress = (e: any) => {
      setShowProg({ display: true, body: e });
      if (e.progress === 1) {
        setShowProg({ display: false, body: { progress: 0 } });
      }
      console.log("onUploadProgress", e);
    };
    fetchData(conf, onSuccess, onErrorsModal);
  };
  const onImageEdit = (formData: any) => {
    fetchData(
      updateDetailAccomodationImageConf(formData),
      onSuccess,
      onErrorsModal
    );
  };

  return (
    <>
      <div className="header-container">
        <h2 className="page-title">{t("accomodation.image.doctitle")}</h2>
        {!isOperatore && <Button
          onClick={createImage}
          color={templateTableEditClass}
          style={{ float: "right", marginTop: "5px" }}
        >
          <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faPlus} />{" "}
          {t("accomodation.image.add")}
        </Button> }
      </div>

      <Table striped responsive>
        <thead>
          <tr style={{ whiteSpace: "break-spaces" }}>
            <th style={{ width: "20%" }}>{t("accomodation.image.date")}</th>
            <th style={{ width: "60%" }}>{t("accomodation.image.preview")}</th>
            <th style={{ width: "15%" }} />
          </tr>
        </thead>
        <tbody>
          {images?.map((el: any) => (
            <tr>
              <td>{formattaFullData(el.date, t)}</td>
              <td>
                {el.file && el.file.preview && (
                  <img
                    src={el.file.preview}
                    alt="preview"
                    style={{ maxWidth: "100%", height: "auto" }}
                  ></img>
                )}
              </td>
              <td>
                <ButtonGroup>
                  {el.file && (
                    <DownloadButton
                      inTable={true}
                      url={el.file.url}
                      filename={el.file.originalname}
                    />
                  )}
                   { isAdministrator && <DeleteButton entityType='accomodation-image' id={el.id} refresh={() => { onSuccess('x') }} /> }
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
        <Modal isOpen={showProg.display}>
          <ModalHeader>Stiamo caricando i documenti</ModalHeader>
          <ModalBody>
            <>
              "sarai indirizzato alla pagina non appena l'operazione sarà
              conclusa"
              <Progress
                animated
                color="success"
                min={0}
                max={100}
                value={showProg.body.progress * 100}
              />
            </>
          </ModalBody>
          <ModalFooter>
            <svg
              viewBox="25 25 50 50"
              className="svgcircle d-none d-md-inline mt-2"
            >
              <circle r="20" cy="50" cx="50"></circle>
            </svg>
          </ModalFooter>
        </Modal>
      </Table>
    </>
  );
}
