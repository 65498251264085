import {
  faCheck,
  faEye,
  faPencil,
  faPlus,
  faRefresh,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Table,
} from "reactstrap";
import {
  changeActivateUserConf,
  changeEnableUserConf,
  getUserListConf,
} from "../../api/userAPI";
import BreadcrumbsUtility from "../../components/breadcrumb";
import MobileButton from "../../components/mobile-button";
import PaginationUtility from "../../components/pagination";
import useRequest from "../../hook/use-request.hook";
import { DetailUser } from "../../model/user.model";
import {
  currentUserSelector,
  typeOptionsADMIN,
  typeOptionsSPORTELLISTA,
} from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { useAppUtilContext } from "../../util/app-util.context";
import {
  templateTableEditClass,
  templateTableShowClass,
} from "../../util/form.util";
import DeleteButton from "../../components/delete-button";

export default function UserListPage() {
  const { onErrorsModal } = useAppUtilContext();
  const currentUser = useAppSelector(currentUserSelector);
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<DetailUser[]>([]);
  const [name, setName] = useState<any>(null);
  const [cod, setCod] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [authorities, setAuthorities] = useState<any>(null);
  const [surname, setSurname] = useState<any>(null);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const handleChange = (e: any) => {
    if (e.target.id === "name") setName(e.target.value);
    else if (e.target.id === "surname") setSurname(e.target.value);
    else if (e.target.id === "cod") setCod(e.target.value);
    else if (e.target.id === "email") setEmail(e.target.value);
    else if (e.target.id === "authorities") setAuthorities(e.target.value);
  };

  const refreshFirstPage = () => {
    setPagination({
      page: 1,
      pageSize: 21,
      totalElements: null,
    });

    refresh(pagination);
  };

  useEffect(() => {
    refreshFirstPage();
  }, []);

  const refresh = (pag: any) => {
    let query = "";
    if (email) query = query + "&email=" + email;
    if (name) query = query + "&name=" + name;
    if (surname) query = query + "&surname=" + surname;
    if (cod) query = query + "&fiscalCode=" + cod;
    if (authorities) query = query + "&authorities=" + authorities;

    fetchData(
      getUserListConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(data);
        setPagination({
          ...pag,
          ...{ totalElements: header["x-total-count"] },
        });
      },
      onErrorsModal
    );
  };

  const isOperatore = currentUser?.authorityChecks.isOperatore;
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;

  let typeOptions: any = [];
  if (isAdministrator || isOperatore) {
    typeOptions = typeOptionsADMIN;
  } else if (isSportellista) {
    typeOptions = typeOptionsSPORTELLISTA;
  }

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility links={[{ text: t("user.list.title") }]} />

      <div className="header-container">
        <h2 className="page-title">{t("user.list.title")}</h2>
      </div>
      <div className="filter-card card mb-2">
        <div className="card-body">
          <h6 className="mt-1 mb-3">{t("general.actions.filter")}</h6>
          <div className="mt-2">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10">
                <FormGroup>
                  <div
                    style={{ marginBottom: ".5rem", display: "inline-block" }}
                  >
                    {t("user.register.email")}
                  </div>
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10">
                <FormGroup>
                  <div
                    style={{ marginBottom: ".5rem", display: "inline-block" }}
                  >
                    {t("user.register.fiscalCode")}
                  </div>
                  <Input
                    id="cod"
                    name="cod"
                    type="text"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-5">
                <FormGroup>
                  <div
                    style={{ marginBottom: ".5rem", display: "inline-block" }}
                  >
                    {t("user.register.first_name")}
                  </div>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5">
                <FormGroup>
                  <div
                    style={{ marginBottom: ".5rem", display: "inline-block" }}
                  >
                    {t("user.register.authorities")}
                  </div>
                  <Input
                    id="authorities"
                    name="authorities"
                    type="select"
                    onChange={handleChange}
                  >
                    <option key={0} value={0}>
                      Nessuno
                    </option>
                    {typeOptions.map((it: any) => {
                      return (
                        <option key={it.id} value={it.id}>
                          {it.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2">
                <div style={{ marginBottom: ".5rem" }}>&nbsp;</div>
                <Button color="primary" onClick={refreshFirstPage}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="justify-content-end pt-3"
        style={{ display: "flex", gap: "10px" }}
      >
        {!isOperatore && (
          <MobileButton
            color="secondary"
            outline
            onClick={() => navigate(`/user/register`)}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="d-none d-md-inline">
              &nbsp; {t("general.buttons.create")}
            </span>
          </MobileButton>
        )}
        <MobileButton
          onClick={() => refresh(pagination)}
          disabled={loading}
          color="secondary"
          outline
        >
          {!loading ? (
            <>
              <FontAwesomeIcon icon={faRefresh} />
              <span className="d-none d-md-inline">
                &nbsp; {t("general.buttons.refresh")}
              </span>
            </>
          ) : (
            <>
              <svg
                viewBox="25 25 50 50"
                className="svgcircle d-none d-md-inline mt-2"
              >
                <circle r="20" cy="50" cx="50"></circle>
              </svg>
            </>
          )}
        </MobileButton>
      </div>
      {pagination.totalElements == 0 && (
        <div className="pb-3 pt-3 fs-5">
          {t("general.errors.no_items_found")}
        </div>
      )}
      {pagination.totalElements > 0 && (
        <div className="mt-2 row">
          <Table striped responsive>
            <thead>
              <tr style={{ whiteSpace: "break-spaces" }}>
                <th style={{ width: "10%" }}>{t("general.id")}</th>
                <th style={{ width: "10%" }}>
                  {t("user.register.first_name")}
                </th>
                <th style={{ width: "10%" }}>{t("user.register.last_name")}</th>
                <th style={{ width: "10%" }}>{t("user.register.email")}</th>
                {isAdministrator && (
                  <th style={{ width: "10%" }}>
                    {t("user.register.authorities")}
                  </th>
                )}
                <th style={{ width: "15%", textAlign: "center" }}>
                  {t("user.register.status")}
                </th>

                <th rowSpan={2} style={{ width: "13%" }}></th>
              </tr>
              <tr style={{ whiteSpace: "break-spaces" }}></tr>
            </thead>
            <tbody>
              {list.map((it, idx) => {
                return (
                  <tr key={it.id}>
                    <td style={{ width: "auto" }}>{it.id}</td>
                    <td style={{ width: "auto" }}>{it.firstName}</td>
                    <td style={{ width: "auto" }}>{it.lastName}</td>
                    <td style={{ width: "auto" }}>{it.email}</td>
                    {isAdministrator && (
                      <td style={{ width: "auto" }}>
                        {it.authorities?.join(", ")}
                      </td>
                    )}
                    <td style={{ width: "auto", textAlign: "center" }}>
                      {it.activated ? (
                        <Badge style={{ fontSize: "16.8px" }} color="success">
                          {t("user.active")}
                        </Badge>
                      ) : (
                        <Badge style={{ fontSize: "16.8px" }} color="danger">
                          {t("user.disactive")}
                        </Badge>
                      )}{" "}
                      {isAdministrator && !it.activated && (
                        <MobileButton
                          inTable={true}
                          icon={it.activated ? faXmark : faCheck}
                          color={"link"}
                          size="sm"
                          text={t("general.buttons.active")}
                          id={"view" + it.id}
                          onClick={() => {
                            fetchData(
                              changeActivateUserConf(it.email),
                              (data: any, header: any) => {
                                refresh(pagination);
                              },
                              onErrorsModal
                            );
                          }}
                        />
                      )}
                    </td>

                    <td style={{ width: "auto", textAlign: "center" }}>
                      {!it.activated && (
                        <Badge style={{ fontSize: "16.8px" }} color="secondary">
                          {t("general.buttons.activateAccount")}
                        </Badge>
                      )}

                      {it.activated ? (
                        <>
                          {it.enabled ? (
                            <Badge
                              style={{ fontSize: "16.8px" }}
                              color="success"
                            >
                              {t("user.enabled")}
                            </Badge>
                          ) : (
                            <Badge
                              style={{ fontSize: "16.8px" }}
                              color="warning"
                            >
                              {t("user.disabled")}
                            </Badge>
                          )}{" "}
                          {isAdministrator && (
                            <MobileButton
                              inTable={true}
                              icon={it.enabled ? faXmark : faCheck}
                              color={"link"}
                              size="sm"
                              text={
                                it.enabled
                                  ? t("general.buttons.disable")
                                  : t("general.buttons.enable")
                              }
                              id={"view" + it.id}
                              onClick={() => {
                                fetchData(
                                  changeEnableUserConf(
                                    it.id + "",
                                    it.enabled ? !it.enabled : true
                                  ),
                                  (data: any, header: any) => {
                                    refresh(pagination);
                                  },
                                  onErrorsModal
                                );
                              }}
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      <ButtonGroup style={{ width: "100%" }}>
                        <MobileButton
                          inTable={true}
                          icon={faEye}
                          className="d-block"
                          style={{ width: "100%" }}
                          color={templateTableShowClass}
                          size="sm"
                          text={t("general.buttons.detail")}
                          id={"view" + it.id}
                          onClick={() => navigate(`/user/${it.id}`)}
                        />
                        {!isOperatore && (
                          <MobileButton
                            inTable={true}
                            icon={faPencil}
                            className="d-block"
                            style={{ width: "100%" }}
                            color={templateTableEditClass}
                            size="sm"
                            text={t("general.buttons.edit")}
                            id={"view" + it.id}
                            onClick={() => navigate(`/user/${it.id}/edit`)}
                          />
                        )}
                        {isAdministrator && ( <DeleteButton entityType='user' id={it.id} refresh={() => { refresh(pagination) }} /> )}
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <div className="pb-5">
        <PaginationUtility
          {...pagination}
          onSizeChange={(n: any) => {
            if (pagination.pageSize !== n)
              refresh({ ...pagination, ...{ page: 1, pageSize: n } });
          }}
          onChange={(n: any) => {
            if (pagination.page !== n)
              refresh({ ...pagination, ...{ page: n } });
          }}
        />
      </div>
    </div>
  );
}
