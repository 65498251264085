import {
  faFileExcel,
  faPlus,
  faRefresh,
  faRightFromBracket,
  faRightToBracket,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Button, FormGroup, Input, Label, Table } from "reactstrap";
import { getBadgeExportListConf, getBadgeListConf } from "../../api/badgeAPI";
import { getBadgeInOutListConf, getUserListConf } from "../../api/userAPI";
import MobileButton from "../../components/mobile-button";
import PaginationUtility from "../../components/pagination";
import useRequest from "../../hook/use-request.hook";
import { LookupBadge } from "../../model/badge.model";
import { Authorities } from "../../redux/reducers/userSlice";
import { useAppUtilContext } from "../../util/app-util.context";
import { formattaFullData } from "../../util/form.util";

export interface AddImageModalHandle {
  refresh: () => void;
}

const BadgeListPage = forwardRef<AddImageModalHandle, any>(
  (props: any, ref) => {
    const { isAdminPage } = props;
    const { onErrorsModal } = useAppUtilContext();
    const { loading, fetchData } = useRequest();
    const { fetchData: fetchExport } = useRequest();

    const [list, setList] = useState<LookupBadge[]>([]);
    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);
    const [type, setType] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const [usersList, setUsersList] = useState<any>(null);
    const navigate = useNavigate();

    const [pagination, setPagination] = useState<any>({
      page: 1,
      pageSize: 21,
      totalElements: null,
    });

    useImperativeHandle(ref, () => ({
      refresh() {
        refresh(pagination);
      },
    }));

    const handleChange = (e: any) => {
      if (e.target.id === "fromDate") setFromDate(e.target.value);
      else if (e.target.id === "toDate") setToDate(e.target.value);
      else if (e.target.id === "type") {
        if (e.target.value == 0) setType(null);
        else setType(e.target.value);
      } else if (e.target.id === "user") setUser(e.target.value);
    };

    const refreshFirstPage = () => {
      refresh({
        page: 1,
        pageSize: 21,
        totalElements: null,
      });
    };

    useEffect(() => {
      if (isAdminPage) {
        fetchData(
          getUserListConf(
            1,
            1000,
            "&authorities=" +
              Authorities.PROFESSIONISTA +
              "," +
              Authorities.SPORTELLISTA
          ),
          (data: any, header: any) => {
            setUsersList(data);
          },
          onErrorsModal
        );
      }

      refreshFirstPage();
    }, []);

    const refresh = (pag: any) => {
      let query = "";
      // if (status) query = '&status=' + status;
      if (fromDate) query = query + "&fromDate=" + fromDate;
      if (toDate) query = query + "&toDate=" + toDate;
      if (type) query = query + "&types=" + type;

      if (isAdminPage) {
        if (user && user != 0) query = query + "&userId=" + user;

        fetchData(
          getBadgeListConf(pag.page, pag.pageSize, query),
          (data: any, header: any) => {
            setList(data);
            setPagination({
              ...pag,
              ...{ totalElements: header["x-total-count"] },
            });
          },
          onErrorsModal
        );
      } else {
        fetchData(
          getBadgeInOutListConf(pag.page, pag.pageSize, query),
          (data: any, header: any) => {
            setList(data);
            setPagination({
              ...pag,
              ...{ totalElements: header["x-total-count"] },
            });
          },
          onErrorsModal
        );
      }
    };
    const exportToExcel = () => {
      let query = "";
      // if (status) query = '&status=' + status;
      if (fromDate) query = query + "&fromDate=" + fromDate;
      if (toDate) query = query + "&toDate=" + toDate;
      if (type) query = query + "&types=" + type;
      fetchExport(
        getBadgeExportListConf(query),
        (data: any, headers: any) => {
          const filenameRegex = /filename[^;=\n]*=\s*["']?([^"'\n;]*)["']?/;
          let filename = "export.xlsx";
          let contentType = headers["content-type"];
          let contentDisposition = headers["content-disposition"];
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].trim();
          }
          const blob = new Blob([data], {
            type: contentType
              ? contentType
              : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        },
        onErrorsModal
      );
    };

    return (
      <div className="container mt-2">
        <div className="header-container">
          <h2 className="page-title">
            {isAdminPage ? t("user.badge.adminlist") : t("user.badge.list")}
          </h2>
        </div>
        <div className="filter-card card mb-2">
          <div className="card-body">
            <h6 className="mt-1 mb-3">{t("general.actions.filter")}</h6>
            <div className="mt-2">
              {isAdminPage && (
                <div className="row">
                  <div className="col-lg-10 col-md-10 col-sm-10">
                    <FormGroup className="form-field">
                      <Label className="form-label select" for="user">
                        {t("badge.list.user")}
                      </Label>
                      <Input
                        id="user"
                        name="user"
                        onChange={handleChange}
                        type="select"
                      >
                        <option key={0} value={0}>
                          Nessuno
                        </option>
                        {usersList &&
                          usersList.map((it: any) => {
                            return (
                              <option key={it.id} value={it.id}>
                                {it.id} - {it.firstName} {it.lastName}
                              </option>
                            );
                          })}
                      </Input>
                    </FormGroup>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <FormGroup>
                    <Label className="form-label select" for="user">
                      {t("user.badge.type")}
                    </Label>
                    <Input
                      id="type"
                      name="type"
                      type="select"
                      onChange={handleChange}
                    >
                      <option key={0} value={0}>
                        -
                      </option>
                      {["IN", "OUT"].map((it) => {
                        return (
                          <option key={it} value={it}>
                            {it}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <FormGroup>
                    <Label className="form-label select" for="user">
                      {t("general.date.from")}
                    </Label>
                    <Input
                      id="fromDate"
                      name="fromDate"
                      type="date"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <FormGroup>
                    <Label className="form-label select" for="user">
                      {t("general.date.to")}
                    </Label>
                    <Input
                      id="toDate"
                      name="toDate"
                      type="date"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-2">
                  <div style={{ marginBottom: ".3rem" }}>&nbsp;</div>
                  <Button color="primary" onClick={refreshFirstPage}>
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="justify-content-end pt-3"
          style={{ display: "flex", gap: "10px" }}
        >
          {isAdminPage && (
            <MobileButton color="dark" outline onClick={exportToExcel}>
              <FontAwesomeIcon icon={faFileExcel} />
              <span className="d-none d-md-inline">
                &nbsp; {t("general.buttons.export")}
              </span>
            </MobileButton>
          )}
          {isAdminPage && (
            <MobileButton
              color="secondary"
              outline
              onClick={() => navigate(`/badge/create`)}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="d-none d-md-inline">
                &nbsp; {t("general.buttons.create")}
              </span>
            </MobileButton>
          )}

          <MobileButton
            onClick={() => refresh(pagination)}
            disabled={loading}
            color="secondary"
            outline
          >
            {!loading ? (
              <>
                <FontAwesomeIcon icon={faRefresh} />
                <span className="d-none d-md-inline">
                  &nbsp; {t("general.buttons.refresh")}
                </span>
              </>
            ) : (
              <>
                {/* <Spinner size="sm">Loading...</Spinner>
                <span className="d-none d-md-inline">
                  {" "}
                  {t("general.actions.loading")}{" "}
                </span> */}
                <svg
                  viewBox="25 25 50 50"
                  className="svgcircle d-none d-md-inline mt-2"
                >
                  <circle r="20" cy="50" cx="50"></circle>
                </svg>
              </>
            )}
          </MobileButton>
        </div>
        {list.length > 0 && !loading && (
          <div className="mt-2 row">
            <Table striped responsive>
              <thead>
                <tr style={{ whiteSpace: "break-spaces" }}>
                  <th style={{ width: "10%" }}>{t("general.id")}</th>
                  {isAdminPage && (
                    <th style={{ width: "50%" }}>{t("user.badge.user")}</th>
                  )}
                  <th style={{ width: "50%" }}>{t("user.badge.date")} </th>
                  <th style={{ width: "20%" }}>{t("user.badge.type")} </th>
                </tr>
                <tr style={{ whiteSpace: "break-spaces" }}></tr>
              </thead>
              <tbody>
                {list.map((it, idx) => {
                  return (
                    <tr key={it.id}>
                      <td style={{ width: "auto" }}>{it.id}</td>
                      {isAdminPage && (
                        <td style={{ width: "50%" }}>
                          {it.user && (
                            <>
                              <a
                                href=""
                                onClick={() => navigate(`/user/${it.userId}`)}
                              >
                                {" "}
                                {it.user.firstName} {it.user.lastName}
                              </a>
                            </>
                          )}
                        </td>
                      )}
                      <td style={{ width: "auto" }}>
                        {formattaFullData(it.date, t)}
                      </td>
                      <td style={{ width: "auto", fontSize: "1.4rem" }}>
                        {it.type == "IN" ? (
                          <Badge style={{ display: "block" }} color="success">
                            {t("user.badge.badgeinstatus")}{" "}
                            <FontAwesomeIcon
                              style={{ marginLeft: ".5rem" }}
                              icon={faRightToBracket}
                            />
                          </Badge>
                        ) : (
                          <Badge style={{ display: "block" }} color="warning">
                            {t("user.badge.badgeoutstatus")}{" "}
                            <FontAwesomeIcon
                              style={{ marginLeft: ".5rem" }}
                              icon={faRightFromBracket}
                            />
                          </Badge>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="pb-5">
          <PaginationUtility
            {...pagination}
            onSizeChange={(n: any) => {
              if (pagination.pageSize !== n)
                refresh({ ...pagination, ...{ page: 1, pageSize: n } });
            }}
            onChange={(n: any) => {
              if (pagination.page !== n)
                refresh({ ...pagination, ...{ page: n } });
            }}
          />
        </div>
      </div>
    );
  }
);

export default BadgeListPage;
