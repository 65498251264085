import { t } from "i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Alert, ButtonGroup, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import useRequest from "../../hook/use-request.hook";
import { ApiError } from "../../model/errors.model";
import { useAppUtilContext } from "../../util/app-util.context";
import { ErrorsView } from "../../components/errors.view";
import { withTheme } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";

import { Theme as Bootstrap4Theme } from "@rjsf/bootstrap-4";
import { FormValidation, RJSFSchema, UiSchema } from "@rjsf/utils";
import { FieldTemplate } from "../../components/TemplateFieldShifter";
import MobileButton from "../../components/mobile-button";
import {
  faCheckDouble,
  faCommentSms,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import BreadcrumbsUtility from "../../components/breadcrumb";
import {
  confirmCodeSurveyAnswerConf,
  getDetailSurveyAnswerConf,
  sendCodeSurveyAnswerConf,
} from "../../api/surveyAnswerAPI";
import { DetailSurveyAnswer } from "../../model/survey-answer.model";
import {
  Authorities,
  currentUserSelector,
} from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { checkRequiredAuthorities } from "../../components/authority-boundary-view";
import { patternCodiceFiscale, patternNumeroTelefono, transformErrors } from "../../util/form-util";

const FormWithBootstrap = withTheme(Bootstrap4Theme);

export default function SurveyAnswerConfirmPage() {
  let { id } = useParams();
  const formRef = useRef<any>(null);
  const navigate = useNavigate();
  const { showModal, closeModal, onErrorsModal } = useAppUtilContext();
  const { loading, fetchData } = useRequest<DetailSurveyAnswer>();
  const [entity, setEntity] = useState<DetailSurveyAnswer>();
  const [send, setSend] = useState<boolean>(false);
  const [tab, setTab] = useState<string>("1");
  const currentUser = useAppSelector(currentUserSelector);

  useEffect(() => {
    if (id) {
      fetchData(getDetailSurveyAnswerConf(id), onSuccessDetail, onErrorsModal);
    }
  }, [id]);

  const onSuccessDetail = (data: DetailSurveyAnswer) => {
    let ent = { ...data };
    setEntity({ ...ent });
  };

  const sendCode = (ev: any) => {
    setSend(true);
  };

  const onChange = (props: any) => {
    const { formData } = props;

    setEntity(formData);
  };

  const onSubmit = ({ formData }: any) => {
    if (send) {
      let phone: any = {
        phoneNumber: formData.phoneNumber,
        email: formData.email,
        fiscalCode: formData.fiscalCode,
      };

      fetchData(
        sendCodeSurveyAnswerConf(
          id ? id : "",
          phone.phoneNumber,
          phone.fiscalCode,
          phone.email
        ),
        onSuccessEdit,
        onErrorsModal
      );
    } else {
      fetchData(
        confirmCodeSurveyAnswerConf(id ? parseInt(id, 10) : 0, formData.code),
        onSuccessVerify,
        onErrorsModal
      );
    }
  };

  const onSuccessVerify = (data: any) => {
    let message = data.message;

    showModal(t("modal.request_success"), message, null);

    if (entity && entity.verificationStatus !== "VERIFIED")
      setEntity({ ...entity, ...{ verificationStatus: "VERIFIED" } });
  };
  const onSuccessEdit = (data: any) => {
    let message = data.message;

    showModal(t("modal.request_success"), message, null);

    if (entity && entity.verificationStatus !== "SENT")
      setEntity({ ...entity, ...{ verificationStatus: "SENT" } });

    setSend(false);
  };

  function customValidate(
    formData: any,
    errors: FormValidation<any>,
    uiSchema?: UiSchema<any, RJSFSchema, any> | undefined
  ): FormValidation<any> {
    let emailIsPresent = formData.email && formData.email !== "";
    let fiscalCodeIsPresent = formData.fiscalCode && formData.fiscalCode !== "";

    if (!emailIsPresent && !fiscalCodeIsPresent) {
      //  errors.email = "Options must be unique!";
      errors.email?.addError("Almeno un campo e' obbligatorio");
      errors.fiscalCode?.addError("Almeno un campo e' obbligatorio");
    }

    console.log(errors);
    return errors;
  }

  const schemaInvia: RJSFSchema = {
          type: "object",
          properties: {
            phoneNumber: {
              type: "string",
              title: "Telephone",
              pattern: "^\\+?[0-9]{10,15}$",
              minLength: 10,
              maxLength: 15,
              description: t("general.desc.tel"),
            },
            fiscalCode: {
              type: "string",
              title: "fiscalCode",
              pattern: "^[A-Z]{6}\\d{2}[A-Z]\\d{2}[A-Z]\\d{3}[A-Z]$",
            },
            email: {
              type: "string",
              title: "email",
              format: "email",
            }
          },
        }

  const schemaVerifica: RJSFSchema =
   {
          type: "object",
          properties: {
           
            code: {
              type: "string",
              title: "Code",
              minLength: 6,
            },
          }};

  const uiSchema: any = {
  
  };

  // console.log("entity?.tradSchema", entity?.tradSchema)

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          { text: t("survey-answer.list.title"), link: "/survey-answer" },
          {
            text: t("survey-answer.detail.title"),
            link: "/survey-answer/" + id,
          },
          { text: t("survey-answer.confirm.title") },
        ]}
      />

      <div className="header-container">
        <h2 className="page-title">{t("survey-answer.confirm.title")}</h2>
      </div>
      {entity && entity.verificationStatus == "VERIFIED" && (
        <Alert>{t("modal.answer.request_verified")}</Alert>
      )}
<Nav tabs>
    <NavItem>
      <span
                  className={ tab == "1" ? "nav-link active" :  "nav-link"}
                  onClick={() => setTab("1")} 
      >
        Invia codice via SMS
      </span>
    </NavItem>
    <NavItem>
      <span
                  className={ tab == "2" ? "nav-link active" :  "nav-link"}
                  onClick={() => setTab("2")}    >
        Inserisci codice di verifica
      </span>
    </NavItem>
  </Nav>
      <div className="card">
        
        <div className="card-body">
        <div>
  
  <TabContent activeTab={tab}>
    <TabPane tabId="1">
    <FormWithBootstrap
            ref={formRef}
            formData={entity}
            onSubmit={onSubmit}
            onChange={onChange}
            schema={schemaInvia}
            showErrorList={false}
            transformErrors={transformErrors}
            templates={{
              FieldTemplate,
            }}
            uiSchema={uiSchema}
            validator={validator}
            customValidate={customValidate}
          >
            <div>
              <ButtonGroup
                style={{
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                {entity && (
                  <MobileButton
                    icon={faCommentSms}
                    color={"primary"}
                    size="lg"
                    
                    type="submit"
                    onClick={sendCode}
                    text={t("general.buttons.sendSMS")}
                    disabled={
                      entity &&
                      (entity.verificationStatus == "VERIFIED" ||
                        entity.phoneNumber == null)
                    }
                  />
                )}
               
                {entity && entity.verificationStatus == "VERIFIED" && (
                  <MobileButton
                    icon={faEye}
                    size="lg"
                    color={"primary"}
                    text={t("general.buttons.detail")}
                    type="button"
                    onClick={() => {
                      navigate(`/survey-answer/${id}`);
                      closeModal();
                    }}
                  />
                )}
              </ButtonGroup>
            </div>
          </FormWithBootstrap>
    </TabPane>
    <TabPane tabId="2">
    <FormWithBootstrap
            ref={formRef}
            formData={entity}
            onSubmit={onSubmit}
            onChange={onChange}
            schema={schemaVerifica}
            showErrorList={false}
            transformErrors={transformErrors}
            templates={{
              FieldTemplate,
            }}
            uiSchema={uiSchema}
            validator={validator}
            customValidate={customValidate}
          >
            <div>
              {" "}
              <ButtonGroup
                style={{
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                {entity && (
                  <MobileButton
                    icon={faCheckDouble}
                    size="lg"
                    color={"success"}
                   
                    text={t("general.buttons.verifySMS")}
                    type="submit"
                    disabled={entity && entity.verificationStatus !== "SENT"}
                  />
                )}
                {entity && entity.verificationStatus == "VERIFIED" && (
                  <MobileButton
                    icon={faEye}
                    size="lg"
                    color={"primary"}
                    text={t("general.buttons.detail")}
                    type="button"
                    onClick={() => {
                      navigate(`/survey-answer/${id}`);
                      closeModal();
                    }}
                  />
                )}
              </ButtonGroup>
            </div>
          </FormWithBootstrap>
    </TabPane>
  </TabContent>
</div>








  
        </div>
      </div>
    </div>
  );
}
