import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export function hasCommonElement(arr1: any[], arr2: any[]) {
  return arr1.some((element) => arr2.includes(element));
}

const checkRequiredAuthorities = (
  permittedAuthorities: Authorities[],
  currentUser: CurrentUser | null
) => {
  if (permittedAuthorities.length === 0) {
    return true;
  }
  const currentUserAuthorities = currentUser?.authorities
    ? currentUser?.authorities
    : [];

  let permitted = hasCommonElement(
    currentUserAuthorities,
    permittedAuthorities
  );
  return permitted;
};
export enum Authorities {
  USER = "USER",
  ADMIN = "ADMIN",
  SPORTELLISTA = "SPORTELLISTA",
  OPERATORE_COMUNE = "OPERATORE_COMUNE",
  PROFESSIONISTA = "PROFESSIONISTA",
}

export const typeOptionsADMIN = [
  { id: Authorities.ADMIN, name: "Admin" },
  { id: Authorities.USER, name: "Utente" },
  { id: Authorities.SPORTELLISTA, name: "Sportellista" },
  { id: Authorities.OPERATORE_COMUNE, name: "Operatore comune" },
  { id: Authorities.PROFESSIONISTA, name: "Professionista" },
];
export const typeOptionsSPORTELLISTA = [
  { id: Authorities.USER, name: "Utente" },
  { id: Authorities.PROFESSIONISTA, name: "Professionista" },
];

export interface CurrentUser {
  id: number;
  email: string;
  firstName: string;
  fiscalCode: string;
  lastName: string;
  authorities: Authorities[];
  authorityChecks: AuthCheck;
  badge: any;
}

export interface AuthCheck {
  isAdministrator: boolean;
  isSportellista: boolean;
  isProfessionista: boolean;
  isOperatore: boolean;
  isUser: boolean;
}

export interface UserState {
  accessToken: string | null;
  refreshToken: string | null;
  lang: string | null;
  currentUser: CurrentUser | null;
}

const initialState: UserState = {
  accessToken: null,
  refreshToken: null,
  lang: "it",
  currentUser: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearState: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.currentUser = null;
      return state;
    },
    logout: (state) => {
      //elimino le pendingStay
      localStorage.removeItem("pendingStay");
      state.currentUser = null;
      state.accessToken = null;
      state.refreshToken = null;
      return state;
    },
    signinSuccess: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    currentUserSuccess: (state, action) => {
      const { currentUser } = action.payload;

      const isAdministrator = checkRequiredAuthorities(
        [Authorities.ADMIN],
        currentUser
      );
      const isSportellista = checkRequiredAuthorities(
        [Authorities.SPORTELLISTA],
        currentUser
      );
      const isProfessionista = checkRequiredAuthorities(
        [Authorities.PROFESSIONISTA],
        currentUser
      );
      const isOperatore = checkRequiredAuthorities(
        [Authorities.OPERATORE_COMUNE],
        currentUser
      );
      const isUser = checkRequiredAuthorities([Authorities.USER], currentUser);

      state.currentUser = {
        ...currentUser,
        authorityChecks: {
          isAdministrator,
          isSportellista,
          isProfessionista,
          isOperatore,
          isUser,
        },
      };
    },
    changeUserLanguage: (state, action) => {
      state.lang = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    // builder
    // .addCase(signinAsync.pending, (state) => {
    //     state.isLoading = true;
    //     state.isError = false;
    //     state.isSuccess = false;
    //     state.errors = []
    // })
    // .addCase(signinAsync.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.isSuccess = true;
    //     state.jwt = action.payload.jwt;
    // })
    // .addCase(signinAsync.rejected, (state, action) => {
    //     let payload = action.payload as any;
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.errors = payload.errors;
    // });
  },
});

export const {
  logout,
  clearState,
  signinSuccess,
  currentUserSuccess,
  changeUserLanguage,
} = userSlice.actions;

export const userAccessTokenSelector = (state: RootState) =>
  state.user.accessToken;
export const userLngSelector = (state: RootState) => state.user.lang || "it";
export const currentUserSelector = (state: RootState) => state.user.currentUser;

export default userSlice.reducer;
