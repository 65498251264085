// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-custom-menu {
    background-color: #f8f8f8;
    border-radius: 5px;
  }
  
  .my-custom-option {
    padding: 10px;
    cursor: pointer;
  }
  
  .my-custom-option-selected {
    background-color: #E9ECEF !important;
    color: f8f8f8;
    padding: 10px;
  }
  
  .my-custom-option:hover {
    background-color: #F1F2F4 !important;
    color: black !important;
  }`, "",{"version":3,"sources":["webpack://./src/util/custom-search-widget.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,oCAAoC;IACpC,aAAa;IACb,aAAa;EACf;;EAEA;IACE,oCAAoC;IACpC,uBAAuB;EACzB","sourcesContent":[".my-custom-menu {\n    background-color: #f8f8f8;\n    border-radius: 5px;\n  }\n  \n  .my-custom-option {\n    padding: 10px;\n    cursor: pointer;\n  }\n  \n  .my-custom-option-selected {\n    background-color: #E9ECEF !important;\n    color: f8f8f8;\n    padding: 10px;\n  }\n  \n  .my-custom-option:hover {\n    background-color: #F1F2F4 !important;\n    color: black !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
