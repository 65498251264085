import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createDetailAccomodationConf,
  getAccomodationTypesConf,
  getDetailAccomodationConf,
  updateDetailAccomodationConf,
} from "../../api/accomodationAPI";
import BreadcrumbsUtility from "../../components/breadcrumb";
import useRequest from "../../hook/use-request.hook";
import { DetailAccomodation } from "../../model/accomodation.model";
import { currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { useAppUtilContext } from "../../util/app-util.context";
import {
  defaultUiSchema,
  FormWithBootstrap,
  objectDefined,
  patternZip,
  templates,
  transformErrors,
} from "../../util/form-util";
import AddAccomodationImages from "./add-accomodation-images.view";
import { getRealEstateAgencyListConf } from "../../api/realEstateAgencyAPI";
import { getAccomodationOwnerListConf } from "../../api/accomodationOwnerAPI";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { templateTableEditClass } from "../../util/form.util";
import { faFloppyDisk, faPencil } from "@fortawesome/free-solid-svg-icons";
import MobileButton from "../../components/mobile-button";

export default function RealEstateDetailPage(props: any) {
  const { showNotification } = useAppUtilContext();
  const { edit } = props;

  let { id } = useParams();
  const navigate = useNavigate();
  const { showModal, onErrorsModal } = useAppUtilContext();
  const { fetchData: fetchDataDetail } = useRequest<any>();
  const [entity, setEntity] = useState<DetailAccomodation>();
  const [types, setTypes] = useState<string[]>([]);
  const { loading, fetchData } = useRequest();
  const [realEstateAgencies, setRealEstateAgencies] = useState<any[]>([]);
  const [accomodationOwners, setAccomodationOwners] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailAccomodationConf(id),
        onSuccessDetail,
        onErrorsModal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchData(
      getRealEstateAgencyListConf(1, 1000, ""),
      (data: any, header: any) => {
        setRealEstateAgencies(data);
      },
      onErrorsModal
    );
    fetchData(
      getAccomodationTypesConf(),
      (data: any, header: any) => {
        setTypes(data);
      },
      onErrorsModal
    );
    fetchData(
      getAccomodationOwnerListConf(1, 1000, ""),
      (data: any, header: any) => {
        setAccomodationOwners(data);
      },
      onErrorsModal
    );
  }, []);

  const onSuccessDetail = (dto: DetailAccomodation) => {
    let e = dto;

    if (e.accomodationOwnerId) e.isPrivate = true;
    else e.isPrivate = false;

    setEntity(e);
  };

  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;

  const datas = objectDefined({
    ...entity,
    isPrivate: entity?.isPrivate || false,
    accomodationOwnerId: entity?.isPrivate ? entity.accomodationOwnerId : null,
  });

  const schema: RJSFSchema = {
    // title: t('accomodation.title'),
    type: "object",
    required: [
      "address",
      "city",
      "zip",
      "number",
      "externalMq",
      "internalMq",
      "sheet",
      "particle",
      "subordinate",
      "typology",
    ],
    properties: {
      id: {
        title: t("general.id"),
        type: "number", "minimum": 0,
      },
      address: {
        type: "string",
        title: t("accomodation.address"),
      },
      city: {
        type: "string",
        title: t("accomodation.city"),
      },
      zip: {
        type: "string",
        title: t("accomodation.zip"),
        pattern: patternZip,
      },
      number: {
        title: t("accomodation.number"),
        type: "string",
      },
      externalMq: {
        title: t("accomodation.externalmq"),
        type: "number", "minimum": 0,
      },
      internalMq: {
        title: t("accomodation.internalmq"),
        type: "number", "minimum": 0,
      },
      sheet: {
        title: t("accomodation.sheet"),
        type: "string",
      },
      particle: {
        title: t("accomodation.particle"),
        type: "string",
      },
      subordinate: {
        title: t("accomodation.subordinate"),
        type: "string",
      },
      typology: {
        title: t("accomodation.typology"),
        type: "string",
        oneOf: types
          ? types.map((el: any) => {
              return { const: el.name };
            })
          : [],
      },
      conservationStatus: {
        title: t("accomodation.conservationStatus"),
        type: "string",
      },
      hasBox: {
        title: t("accomodation.hasBox"),
        type: "boolean",
      },
      hasElevator: {
        title: t("accomodation.hasElevator"),
        type: "boolean",
      },
      hasAutoclave: {
        title: t("accomodation.hasAutoclave"),
        type: "boolean",
      },
      hasCondominiumGreenery: {
        title: t("accomodation.hasCondominiumGreenery"),
        type: "boolean",
      },
      electricalSystemUpToStandard: {
        title: t("accomodation.electricalSystemUpToStandard"),
        type: "boolean",
      },
      hasSatelliteSystem: {
        title: t("accomodation.hasSatelliteSystem"),
        type: "boolean",
      },
      hasCloset: {
        title: t("accomodation.hasCloset"),
        type: "boolean",
      },
      hasCellar: {
        title: t("accomodation.hasCellar"),
        type: "boolean",
      },
      hasIndependentHeating: {
        title: t("accomodation.hasIndependentHeating"),
        type: "boolean",
      },
      hasCentralHeating: {
        title: t("accomodation.hasCentralHeating"),
        type: "boolean",
      },
      hasDoubleBathrooms: {
        title: t("accomodation.hasDoubleBathrooms"),
        type: "boolean",
      },
      hasAirConditioningSystem: {
        title: t("accomodation.hasAirConditioningSystem"),
        type: "boolean",
      },
      hasDoorman: {
        title: t("accomodation.hasDoorman"),
        type: "boolean",
      },
      interiorDescription: {
        title: t("accomodation.interiorDescription"),
        type: "string",
      },

      isPrivate: {
        title: t("accomodation.isPrivate"),
        type: "boolean",
        default: false,
      },
      assigneeId: {
        title: t("accomodation.assigneeId"),
        type: "number"
      },
    },
    dependencies: {
      hasAirConditioningSystem: {
        oneOf: [
          {
            properties: {
              hasAirConditioningSystem: {
                const: true,
              },
              nrAirConditioningSystem: {
                title: t("accomodation.nrAirConditioningSystem"),
                type: "number", "minimum": 0,
              },
            },
          },
          {
            properties: {
              hasAirConditioningSystem: {
                const: false,
              },
            },
          },
        ],
      },
      isPrivate: {
        oneOf: [
          {
            properties: {
              isPrivate: {
                const: true,
              },
              accomodationOwnerId: {
                title: t("accomodation.accomodationOwnerId"),
                type: "number",
                oneOf: [
                  ...accomodationOwners.map((el) => {
                    return { const: el.id, title: el.name + " " + el.surname };
                  }),
                ],
              },
            },
          },
          {
            properties: {
              isPrivate: {
                const: false,
              },
              realEstateAgencyId: {
                title: t("accomodation.realEstateAgencyId"),
                type: "number", 
                oneOf: realEstateAgencies.map((el) => {
                  return { const: el.id, title: el.name };
                }),
              },
            },
          },
        ],
      },
    },
  };

  const uiSchema = {
    ...defaultUiSchema,
    id: { "ui:widget": "hidden" },
    assigneeId: { "ui:widget": "hidden" },
    typology: {
      "ui:disabled": entity?.id !== undefined,
    },
    "ui:order": [
      "address",
      "city",
      "zip",
      "number",
      "internalMq",
      "externalMq",
      "sheet",
      "particle",
      "subordinate",
      "typology",
      "conservationStatus",
      "hasBox",
      "hasElevator",
      "hasAutoclave",
      "hasCondominiumGreenery",
      "electricalSystemUpToStandard",
      "hasSatelliteSystem",
      "hasCloset",
      "hasCellar",
      "hasDoubleBathrooms",
      "hasIndependentHeating",
      "hasCentralHeating",
      "hasAirConditioningSystem",
      "hasDoorman",
      "nrAirConditioningSystem",
      "interiorDescription",
      "isPrivate",
      "accomodationOwnerId",
      "realEstateAgencyId",
      "id",
      "assigneeId",
    ],
    "ui:options": {
      customTemplate: "MultiColumns",
      cols: {
        city: 4,
        zip: 4,
        number: 4,
        internalMq: 6,
        externalMq: 6,
        hasSatelliteSystem: 4,
        hasElevator: 4,
        hasCondominiumGreenery: 4,
        hasAutoclave: 4,
        hasCloset: 4,
        hasCellar: 4,
        subordinate: 4,
        particle: 4,
        sheet: 4,
        hasBox: 4,
        hasIndependentHeating: 4,
        hasDoorman: 4,
        electricalSystemUpToStandard: 4,
        hasAirConditioningSystem: 4,
        hasCentralHeating: 4,
        hasDoubleBathrooms: 4,
      },
    },
  };
  const onErrors = (errors: any) => {
    showModal(t("modal.request_success"), errors[0].message, null);
  };

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t("modal.request_success"), message, null);
    if (isAdministrator || isSportellista) navigate("/accomodation");
  };

  const formRef = useRef<any>(null);
  const onFormSubmit = ({ formData }: any) => {
    console.log("onFormSubmit");
    let valid = formRef.current.validateForm();
    if (!valid) {
      return;
    }
    let f = { ...formData };

    if (f.isPrivate) {
      delete f.realEstateAgencyId;
      if(f.accomodationOwnerId == null)
        delete f.accomodationOwnerId;
    } else {
      delete f.accomodationOwnerId;
      if(f.realEstateAgencyId == null)
        delete f.realEstateAgencyId;
    }

    delete f.isPrivate;
    if (id) {
      fetchData(updateDetailAccomodationConf(f), onSuccess, onErrors);
    } else {
      fetchData(createDetailAccomodationConf(f), onSuccess, onErrors);
    }
  };

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          {
            text: t("accomodation.list.title"),
            link: "/accomodation",
          },
          { text: t("accomodation.detail.title") },
        ]}
      />
      <div className="header-container">
        <h2 className="page-title">{t("accomodation.detail.title")}</h2>

        {(isAdministrator || isSportellista) && id && !edit && (
          <Button
            onClick={() => navigate(`/accomodation/${id}/edit`)}
            color={templateTableEditClass}
            style={{ float: "right", marginTop: "5px" }}
          >
            <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faPencil} />{" "}
            {t("general.buttons.edit")}
          </Button>
        )}
      </div>
      <FormWithBootstrap
        ref={formRef}
        templates={templates}
        onSubmit={onFormSubmit}
        formData={datas}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        transformErrors={transformErrors}
        showErrorList={false}
        readonly={!edit}
        children={!edit}
        noHtml5Validate={true}
        focusOnFirstError={true}
      ></FormWithBootstrap>
      {!edit && <AddAccomodationImages></AddAccomodationImages>}
    </div>
  );
}
