import Select from 'react-select';
import './custom-search-widget.css';
const customStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#f0f0f0' : 'white', // Remove blue and set custom color
    color: 'black', // Text color
    '&:hover': {
      backgroundColor: '#e0e0e0', // Hover color
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'black', // Text color of the selected item
  }),
};

const CustomSearchWidget = (props: any) => {
  console.log('props', props);
  const { value, onChange, options } = props;
  console.log('value', value);
  console.log('options', options.enumOptions);
  //   const formattedOptions = options.enumOptions.map((option: any) => ({
  //     value: option.id, // Store the ID as the value
  //     label: option.name, // Display name with emoji
  //   }));
  //   console.log('formattedOptions', formattedOptions);

  const onValueChange = (newValue: any) => {
    console.log('onValueChange', newValue);
    onChange(newValue.value);
  };

  return (
    <Select
      options={options.enumOptions}
      value={options.enumOptions.find((opt: any) => opt.value === value)}
      unstyled
      onChange={onValueChange}
      isSearchable
      classNames={{
        control: () => 'form-control',
        menu: () => 'my-custom-menu',
        menuList: () => 'my-custom-menu-list',
        option: ({ isSelected }) =>
          isSelected ? 'my-custom-option-selected' : 'my-custom-option',
      }}
    />
  );
};

export default CustomSearchWidget;
