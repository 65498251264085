import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { createDetailSurveyAnswerConf } from "../../api/surveyAnswerAPI";
import { getDetailSurveyTemplateConf } from "../../api/surveyTemplateAPI";
import BreadcrumbsUtility from "../../components/breadcrumb";
import useRequest from "../../hook/use-request.hook";
import { DetailSurveyTemplate } from "../../model/survey-template.model";
import {
  currentUserSelector,
  userLngSelector,
} from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { useAppUtilContext } from "../../util/app-util.context";
import { isStringified } from "./survey-template-edit.page";
import SurveyTemplateStatic from "./survey-template-static";

export default function SurveyTemplateAnswerPage() {
  let { id } = useParams();
  const navigate = useNavigate();

  const { showModal, closeModal, onErrorsModal } = useAppUtilContext();
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;

  const { fetchData } = useRequest<DetailSurveyTemplate>();
  const [entity, setEntity] = useState<DetailSurveyTemplate>();
  const [datas, setDatas] = useState<any>({});
  const lang = useAppSelector(userLngSelector);

  const [jsonSchema, setJsonSchema] = useState<any[]>();
  const [uiSchema, setUiSchema] = useState<any>();
  const [tradSchema, setTradSchema] = useState<any[]>();

  useEffect(() => {
    if (id) {
      fetchData(
        getDetailSurveyTemplateConf(id),
        onSuccessDetail,
        onErrorsModal
      );
    }
  }, [id]);

  useEffect(() => {
    if (entity) {
      let schemaObj = entity.jsonSchema;
      let uiObj = entity.uiSchema;
      let tradObj = entity.tradSchema;
      setJsonSchema(schemaObj);
      setUiSchema(uiObj);
      setTradSchema(tradObj);
    }
  }, [entity, lang]);

  const onSuccessDetail = (data: DetailSurveyTemplate) => {
    let ent = { ...data };
    let title = isStringified(data.title);
    setEntity({ ...ent, ...{ title: title, fullTitle: data.title } });
  };

  const saveData = (ev: any) => {
    fetchData(
      createDetailSurveyAnswerConf({
        surveyTemplateId: parseInt(id as string),
        jsonDatas: ev,
      }),
      (data) => {
        let d: any = data;

        showModal(
          t("modal.request_success"),
          t("modal.answer.request_success"),
          null,
          <Button
            color="primary"
            onClick={() => {
              navigate(`/survey-answer/${d.data.id}/SMSconfirm`);
              closeModal();
            }}
          >
            {t("general.buttons.verify")}
          </Button>
        );
      },
      onErrorsModal
    );
  };

  return (
    <div className="container mt-2">
      {currentUser && isAdministrator ? (
        <BreadcrumbsUtility
          links={[
            { text: t("survey-template.list.title"), link: "/survey-template" },
            { text: t("survey-template.detail.title") },
          ]}
        />
      ) : (
        <BreadcrumbsUtility
          links={[{ text: entity?.title ? entity?.title[lang] : "..." }]}
        />
      )}
      <div className="header-container">
        {currentUser && isAdministrator ? (
          <h2 className="page-title">
            {t("survey-answer.create.title") + " : " + entity?.title
              ? entity?.title[lang]
              : ""}{" "}
          </h2>
        ) : (
          <h2 className="page-title">
            {entity?.title ? entity?.title[lang] : ""}
          </h2>
        )}
      </div>
      {!entity?.enabled && (
        <Alert color="danger">{t("general.errors.disabled")}</Alert>
      )}
      {Array.isArray(jsonSchema) &&
        entity?.enabled &&
        jsonSchema.length > 0 && (
          <div>
            <div className="card-body">
              <SurveyTemplateStatic
                onSubmit={saveData}
                jsonSchema={jsonSchema}
                uiSchema={uiSchema}
                tradSchema={tradSchema}
                formDatas={datas}
                isTradEnabled={false}
                isReadMode={false}
              />
            </div>
          </div>
        )}
    </div>
  );
}
