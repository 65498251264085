import validator from "@rjsf/validator-ajv8";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { getResetPasswordConf } from "../../api/userAPI";
import useRequest from "../../hook/use-request.hook";
import { useAppUtilContext } from "../../util/app-util.context";

import { RJSFSchema } from "@rjsf/utils";
import {
  FormWithBootstrap,
  templates,
  transformErrors,
} from "../../util/form-util";

export default function ResetPasswordPage() {
  let { code } = useParams();
  const { showModal, onErrorsModal } = useAppUtilContext();
  const { t } = useTranslation();
  const { loading, fetchData } = useRequest();
  const navigate = useNavigate();

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t("modal.request_success"), message, null);
    navigate("/");
  };

  let datas: any = {};

  const schema: RJSFSchema = {
    title: t("user.reset_password.title"),
    type: "object",
    required: ["email", "password", "confirmPassword"],
    properties: {
      email: {
        type: "string",
        title: t("user.login.username"),
        format: "email",
      },
      password: {
        type: "string",
        title: t("user.login.password"),
        pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$",
        // Should contain at least a capital letter
        // Should contain at least a small letter
        // Should contain at least a number
        // Should contain at least a special character
        // And minimum length
        // aD3$12345
      },
      confirmPassword: {
        type: "string",
        title: t("user.login.confirm_password"),
      },
    },
  };

  const uiSchema = {
    password: {
      "ui:widget": "password",
    },
    confirmPassword: {
      "ui:widget": "password",
    },
  };

  const onFormSubmit = ({ formData }: any) => {
    const { email, password } = formData;
    let body = { email, password, code };
    fetchData(getResetPasswordConf(body), onSuccess, onErrorsModal);
  };

  const transformErrorsCustom = (errors: any) => {
    let e = transformErrors(errors);

    e = e.map((error: any) => {
      if (error.name === "pattern" && error.property === ".password") {
        error.message = t("general.errors.password");
      }
      return error;
    });

    if (datas.formData.password !== datas.formData.confirmPassword) {
      e.push({
        stack: ".confirmPassword: Passwords do not match",
        message: t("user.reset_password.password_not_match"),
        name: "match",
        property: ".confirmPassword",
      });
    }

    return e;
  };

  return (
    <div className="container">
      <Card>
        <CardBody>
          <FormWithBootstrap
            templates={templates}
            onSubmit={onFormSubmit}
            onChange={(data) => {
              datas = { ...data };
            }}
            formData={datas}
            schema={schema}
            uiSchema={uiSchema}
            validator={validator}
            transformErrors={transformErrorsCustom}
            showErrorList={false}
            readonly={loading}
          ></FormWithBootstrap>
        </CardBody>
      </Card>
    </div>
  );
}
