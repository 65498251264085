import { DetailAccomodationOwner } from "../model/accomodationOwner.model";
import { defaultHeaders } from "./common";

export const getAccomodationOwnerListConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: "get",
    url:
      "/api/accomodation-owner?page=" +
      page +
      "&size=" +
      size +
      query +
      "&sort=id,desc",
    headers: defaultHeaders,
  };
};
export const getDetailAccomodationOwnerConf = (id: string) => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/accomodation-owner/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailAccomodationOwnerConf = (
  realEstateAgency: DetailAccomodationOwner
) => {
  return {
    method: "put",
    maxBodyLength: Infinity,
    url: `/api/accomodation-owner/${realEstateAgency.id}`,
    headers: defaultHeaders,
    data: JSON.stringify(realEstateAgency),
  };
};

export const createDetailAccomodationOwnerConf = (
  realEstateAgency: DetailAccomodationOwner
) => {
  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/accomodation-owner`,
    headers: defaultHeaders,
    data: JSON.stringify(realEstateAgency),
  };
};
