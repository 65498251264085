import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppUtilContext } from "../../util/app-util.context";
import { FormWithBootstrap, templates } from "../../util/form-util";

import {
  faPencil,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Table,
} from "reactstrap";
import {
  createAppointmentDocumentConf,
  deleteAppointmentDocumentConf,
  getAppointmentDocumentListConf,
  updateDetailAppointmentDocumentConf,
} from "../../api/apointmentDocumentAPI";
import DownloadButton from "../../components/DownloadButton";
import MobileButton from "../../components/mobile-button";
import useRequest from "../../hook/use-request.hook";
import FileWidget from "../../util/file-widget";
import { templateTableEditClass } from "../../util/form.util";
import { title } from "process";
import DeleteButton from "../../components/delete-button";
import { currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";

export default function AddApointmentDocument() {
  let { id } = useParams();
  const formRef = useRef<any>(null);
  const [images, setImages] = useState<any>();
  const { loading, fetchData } = useRequest<any>();
  const [entity, setEntity] = useState<any>();
  const { t } = useTranslation();
  const [showProg, setShowProg] = useState<any>({
    display: false,
    body: { progress: 0 },
  });
  const currentFileRef = useRef<File>();
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;

  useEffect(() => {
    if (id) {
      fetchData(
        getAppointmentDocumentListConf("?appointmentId=" + id),
        onSuccessDetail,
        onErrorsModal
      );
    }
  }, [id]);

  const { onSuccessModal, onErrorsModal, showModal, closeModal } =
    useAppUtilContext();

  const onSuccessDetail = (dto: any) => {
    setImages(dto);
    closeModal();
  };

  const editImage = (editId: any) => {
    const data = editId ? images.find((el: any) => el.id === editId) : {};
    // console.log("editImage data", data)
    setEntity(data);
  };
  const createImage = () => {
    setEntity({ appointmentId: parseInt(id ? id : "0") });
  };

  const onChange = (props: any) => {
    // setEntity(formData);
  };

  const onFileChange = (file: File) => {
    currentFileRef.current = file;
  };
  const onSubmit = ({ formData }: any) => {
    closeModal();
    if (formData.id) {
      onAppointmentDocumentEdit({ ...formData });
    } else {
      onAppointmentDocumentCreate({
        ...formData,
        file: currentFileRef.current,
      });
    }
  };

  const onSuccess = (props: any) => {
    fetchData(
      getAppointmentDocumentListConf("?appointmentId=" + id),
      onSuccessDetail,
      onErrorsModal
    );
  };

  useEffect(() => {
    console.log(entity, "entity");
    if (entity) {
      const schema: RJSFSchema = !entity.id
        ? {
            title: "",
            type: "object",
            properties: {
              id: {
                type: "number",
                minimum: 0,
                title: t("general.id"),
                readOnly: true,
              },
              appointmentId: {
                type: "number",
                minimum: 0,
                title: t("appointment.document.appointmentId"),
                readOnly: true,
              },
              title: {
                type: "string",
                title: t("appointment.document.title"),
              },
              notes: {
                type: "string",
                title: t("appointment.document.notes"),
              },
              documentData: {
                type: "string",
                title: t("appointment.document.upload"),
                format: "data-url",
              },
            },
            required: ["documentData", "title"],
          }
        : {
            title: "",
            type: "object",
            properties: {
              id: {
                type: "number",
                minimum: 0,
                title: t("general.id"),
                readOnly: true,
              },
              appointmentId: {
                type: "number",
                minimum: 0,
                title: t("appointment.document.appointmentId"),
                readOnly: true,
              },
              title: {
                type: "string",
                title: t("appointment.document.title"),
              },
              notes: {
                type: "string",
                title: t("appointment.document.notes"),
              },
            },
            required: ["title"],
          };
      const uiSchema = {
        id: { "ui:widget": "hidden" },
        appointmentId: { "ui:widget": "hidden" },
      };
      const widgets = {
        "data-url": (props: any) => {
          return (
            <FileWidget {...props} onFileChange={onFileChange}></FileWidget>
          );
        },
      };

      showModal(
        entity.id
          ? t("appointment.document.edit")
          : t("appointment.document.add"),
        <>
          <FormWithBootstrap
            formData={entity}
            ref={formRef}
            schema={schema}
            uiSchema={uiSchema}
            onChange={onChange}
            onSubmit={onSubmit}
            templates={templates}
            widgets={widgets}
            validator={validator}
            children={true}
          />{" "}
        </>,
        null,
        <MobileButton
          inTable={true}
          icon={faSave}
          className="d-block"
          style={{ width: "100%" }}
          color={"primary"}
          text={t("general.buttons.send")}
          onClick={() => {
            const err = formRef?.current.validateForm();

            if (err) formRef?.current.submit();
          }}
        />
      );
    }
  }, [entity]);

  const onAppointmentDocumentCreate = (formData: any) => {
    let conf = createAppointmentDocumentConf(formData);
    conf.onUploadProgress = (e: any) => {
      setShowProg({ display: true, body: e });
      if (e.progress === 1) {
        setShowProg({ display: false, body: { progress: 0 } });
      }
      console.log("onUploadProgress", e);
    };
    fetchData(conf, onSuccess, onErrorsModal);
  };

  const onAppointmentDocumentEdit = (formData: any) => {
    let datas = {
      title: formData.title,
      notes: formData.notes,
      id: formData.id,
      appointmentId: formData.appointmentId,
    };
    fetchData(
      updateDetailAppointmentDocumentConf(datas),
      onSuccess,
      onErrorsModal
    );
  };

  const onImageDelete = (editId: any) => {
    fetchData(deleteAppointmentDocumentConf(editId), onSuccess, onErrorsModal);
    //setEntity(null);
  };

  return (
    <>
      <div className="header-container">
        <h2 className="page-title"> {t("appointment.document.doctitle")}</h2>
        <Button
          onClick={createImage}
          color={templateTableEditClass}
          style={{ float: "right", marginTop: "5px" }}
        >
          <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faPlus} />{" "}
          {t("appointment.document.add")}
        </Button>
      </div>

      <Table striped responsive>
        <thead>
          <tr style={{ whiteSpace: "break-spaces" }}>
            <th style={{ width: "60%" }}>
              {t("appointment.document.preview")}
            </th>

            <th style={{ width: "20%" }}> {t("appointment.document.title")}</th>
            <th style={{ width: "20%" }}> {t("appointment.document.notes")}</th>
            <th style={{ width: "15%" }} />
          </tr>
        </thead>
        <tbody>
          {images?.map(
            (el: any) => (
              console.log("el", el),
              (
                <tr>
                  <td>
                    {el.file && el.file.preview && (
                      <img
                        src={el.file.preview}
                        alt="preview"
                        style={{ maxWidth: "100%", height: "auto" }}
                      ></img>
                    )}
                  </td>
                  <td>{el.title}</td>
                  <td>{el.notes}</td>
                  <td>
                    <ButtonGroup>
                      {el.file && (
                        <DownloadButton
                          inTable={true}
                          url={el.file.url}
                          filename={el.file.originalname}
                        />
                      )}
                      <MobileButton
                        inTable={true}
                        icon={faPencil}
                        className="d-block"
                        style={{ width: "100%" }}
                        color={templateTableEditClass}
                        size="sm"
                        id={"view" + el.id}
                        onClick={() => editImage(el.id)}
                      />
                     
                     { isAdministrator && <DeleteButton entityType='appointment-document' id={el.id} refresh={() => { onSuccess('x') }} /> }

                    </ButtonGroup>
                  </td>
                </tr>
              )
            )
          )}
        </tbody>
      </Table>
      <Modal isOpen={showProg.display}>
        <ModalHeader>Stiamo caricando i documenti</ModalHeader>
        <ModalBody>
          <>
            "sarai indirizzato alla pagina non appena l'operazione sarà
            conclusa"
            <Progress
              animated
              color="success"
              min={0}
              max={100}
              value={showProg.body.progress * 100}
            />
          </>
        </ModalBody>
        <ModalFooter>
          <svg
            viewBox="25 25 50 50"
            className="svgcircle d-none d-md-inline mt-2"
          >
            <circle r="20" cy="50" cx="50"></circle>
          </svg>
        </ModalFooter>
      </Modal>
    </>
  );
}
