import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validator from '@rjsf/validator-ajv8';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import {
  createDetailAppointmentConf,
  getDetailAppointmentConf,
  updateDetailAppointmentConf,
} from '../../api/appointmentAPI';
import { getUserListConf } from '../../api/userAPI';
import BreadcrumbsUtility from '../../components/breadcrumb';
import useRequest from '../../hook/use-request.hook';
import { DetailUser } from '../../model/user.model';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import {
  NotificationTypes,
  useAppUtilContext,
} from '../../util/app-util.context';
import CustomSearchWidget from '../../util/custom-search-widget';
import { FormWithBootstrap } from '../../util/form-util';
import {
  templateTableEditClass,
  formattaDateTimeToSave,
} from '../../util/form.util';
import AddApointmentDocument from './add-appointment-document.view';

export default function AppointmentCreatePage(params: any) {
  let { id } = useParams();
  const { readonly } = params;
  const { t } = useTranslation();
  const { showModal } = useAppUtilContext();
  const { showNotification } = useAppUtilContext();
  const currentUser = useAppSelector(currentUserSelector);
  const [entity, setEntity] = useState<any>({});
  const [professionisti, setProfessionisti] = useState<DetailUser[]>([]);
  const [users, setUsers] = useState<DetailUser[]>([]);
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();

  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isProfessionista = currentUser?.authorityChecks.isProfessionista;

  useEffect(() => {
    if (id) {
      fetchData(
        getDetailAppointmentConf(id),
        (data: any, header: any) => {
          setEntity({
            ...data,
            ...{
              professionistaId: data.professionista.id,
              userId: data.user.id,
            },
          });
        },
        onErrors
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchData(
      getUserListConf(
        1,
        1000,
        '&authorities=' +
          Authorities.PROFESSIONISTA +
          ',' +
          Authorities.SPORTELLISTA
      ),
      (data: any, header: any) => {
        setProfessionisti(data);
      },
      onErrors
    );
    fetchData(
      getUserListConf(1, 1000, '&authorities=' + Authorities.USER),
      (data: any, header: any) => {
        setUsers(data);
      },
      onErrors
    );
  }, []);

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    if (isAdministrator || isSportellista) navigate('/appointment');
  };

  const onErrors = (errors: any) => {
    // console.log('errors', errors);
    showNotification(errors[0].message, NotificationTypes.ERROR); // Shows first error message in errors array (missing passwords, etc.)
  };

  const onSubmit = (data: any) => {
    console.log('OnSubmit', data);
    const { professionistaId, userId, title, date, id, notes } = data.formData;

    let pId = isProfessionista ? currentUser.id : professionistaId;

    let formattedDate = '';
    if (date) {
      let array = new Date(date).toLocaleString('it').split(',');
      if (array.length > 1) {
        let part1 = array[0].trim().split('/').reverse().join('-');
        let part2 = array[1].trim();
        formattedDate = `${part1} ${part2}`;
      }
    }

    if (id) {
      fetchData(
        updateDetailAppointmentConf({
          id,
          userId: parseInt(userId, 10),
          professionistaId: pId,
          title,
          date: formattedDate,
          notes,
        }),
        onSuccess,
        onErrors
      );
    } else {
      fetchData(
        createDetailAppointmentConf({
          userId: parseInt(userId, 10),
          professionistaId: pId,
          title,
          date: formattedDate,
          notes,
        }),
        onSuccess,
        onErrors
      );
    }
  };

  let data = { ...entity };

  let schema: any = {
    type: 'object',
    required: ['title', 'notes', 'date', 'userId'],
    properties: {
      title: {
        type: 'string',
        title: t('appointment.title'),
      },
      notes: {
        type: 'string',
        title: t('appointment.notes'),
      },
      date: {
        type: 'string',
        format: 'date-time',
        title: t('appointment.date'),
      },
    },
  };
  if (!isProfessionista) {
    schema.required.push('professionistaId');
    schema.properties['professionistaId'] = {
      type: 'number',
      title: t('appointment.professionista-sportellista'),
      enum: professionisti ? professionisti.map((it) => it.id) : [],
      enumNames: professionisti
        ? professionisti.map((it) =>
            it.firstName == null && it.lastName == null
              ? it.fiscalCode
              : it.firstName + ' ' + it.lastName
          )
        : [],
    };
  }

  schema.properties['userId'] = {
    type: 'number',
    title: t('appointment.user'),
    enum: users ? users.map((it) => it.id) : [],
    enumNames: users
      ? users.map((it) =>
          it.firstName == null && it.lastName == null
            ? it.fiscalCode
            : it.firstName + ' ' + it.lastName
        )
      : [],
  };
  let uiSchema = {
    userId: {
      'ui:widget': CustomSearchWidget,
    },
    professionistaId: {
      'ui:widget': CustomSearchWidget,
    },
  };
  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          { text: t('appointment.list.title'), link: '/appointment' },
          {
            text: id
              ? readonly
                ? t('appointment.detail')
                : t('appointment.edit.title')
              : t('appointment.create.title'),
          },
        ]}
      />

      <div className="header-container">
        <h2 className="page-title">
          {' '}
          {id
            ? readonly
              ? t('appointment.detail')
              : t('appointment.edit.title')
            : t('appointment.create.title')}
          {(isAdministrator || isSportellista) && id && readonly && (
            <Button
              onClick={() => navigate(`/appointment/${id}/edit`)}
              color={templateTableEditClass}
              style={{ float: 'right', marginTop: '5px' }}
            >
              <FontAwesomeIcon
                style={{ marginRight: '1rem' }}
                icon={faPencil}
              />{' '}
              {t('general.buttons.edit')}
            </Button>
          )}
        </h2>
      </div>

      <FormWithBootstrap
        formData={data}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        onSubmit={onSubmit}
      />

      {!isSportellista && readonly && <AddApointmentDocument />}
    </div>
  );
}
