import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppUtilContext } from "../../util/app-util.context";
import { FormWithBootstrap, templates } from "../../util/form-util";

import {
  faPencil,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Table,
} from "reactstrap";
import {
  createDetailAccomodationInspectionImageConf,
  deleteAccomodationInspectionImageConf,
  getAccomodationInspectionImageListConf,
} from "../../api/accomodationInspectionImageAPI";
import MobileButton from "../../components/mobile-button";
import { ImageWidget } from "../../components/TemplateFieldShifter";
import useRequest from "../../hook/use-request.hook";
import { templateTableEditClass } from "../../util/form.util";
import DownloadButton from "../../components/DownloadButton";
import { currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import DeleteButton from "../../components/delete-button";

export default function AddAccomodationInspectionImages() {
  let { id } = useParams();
  const { onErrorsModal, showModal, closeModal } = useAppUtilContext();

  const { t } = useTranslation();
  const currentFileRef = useRef<File>();

  const currentUser = useAppSelector(currentUserSelector);
  const isOperatore = currentUser?.authorityChecks.isOperatore;
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;

  const { fetchData } = useRequest<any>();
  const formRef = useRef<any>(null);
  const [images, setImages] = useState<any>();
  const [entity, setEntity] = useState<any>();
  const onFileChange = (file: File) => {
    currentFileRef.current = file;
  };
  const [showProg, setShowProg] = useState<any>({
    display: false,
    body: { progress: 0 },
  });
  const widgets = {
    "data-url": (props: any) => {
      return <ImageWidget {...props} onFileChange={onFileChange}></ImageWidget>;
    },
  };
  useEffect(() => {
    if (id) {
      fetchData(
        getAccomodationInspectionImageListConf(
          "?accomodationInspectionId=" + id
        ),
        onSuccessDetail,
        onErrorsModal
      );
    }
  }, [id]);

  const onSuccessDetail = (dto: any) => {
    setImages(dto);
    closeModal();
  };

  const editImage = (editId: any) => {
    const data = editId ? images.find((el: any) => el.id === editId) : {};

    setEntity(data);
  };
  const createImage = () => {
    setEntity({ accomodationInspectionId: parseInt(id ? id : "0") });
  };

  const onChange = (props: any) => {
  };

  const onSubmit = ({ formData }: any) => {
    closeModal();
    showModal(
      "Stiamo caricando i documenti",
      "sarai indirizzato alla pagina non appena l'operazione sarà conclusa",
      <></>,
      <svg viewBox="25 25 50 50" className="svgcircle d-none d-md-inline mt-2">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    );
    onImageCreate({ ...formData });
  };

  const onSuccess = (props: any) => {
    fetchData(
      getAccomodationInspectionImageListConf("?accomodationInspectionId=" + id),
      onSuccessDetail,
      onErrorsModal
    );
  };

  useEffect(() => {
    if (entity) {
      const schema: RJSFSchema = {
        title: "",
        type: "object",
        properties: {
          imageId: {
            type: "number",
            minimum: 0,
            readOnly: true,
          },
          accomodationInspectionId: {
            type: "number",
            minimum: 0,
            title: t("accomodation.image.accomodationInspectionId"),
            readOnly: true,
          },
          imageData: {
            type: "string",
            title: t("accomodation.image.imageData"),
            format: "data-url",
          },
        },
        required: ["imageData"],
      };
      const uiSchema = {
        imageId: { "ui:widget": "hidden" },
        accomodationInspectionId: { "ui:widget": "hidden" },
      };

      showModal(
        entity.id ? t("accomodation.image.edit") : t("accomodation.image.add"),
        <>
          <FormWithBootstrap
            formData={entity}
            ref={formRef}
            schema={schema}
            uiSchema={uiSchema}
            onChange={onChange}
            onSubmit={onSubmit}
            templates={templates}
            widgets={widgets}
            validator={validator}
            children={true}
          />{" "}
        </>,
        null,
        <MobileButton
          inTable={true}
          icon={faSave}
          className="d-block"
          style={{ width: "100%" }}
          color={"primary"}
          text={t("general.buttons.send")}
          onClick={() => {
            const err = formRef?.current.validateForm();

            if (err) formRef?.current.submit();
          }}
        />
      );
    }
  }, [entity]);

  const onImageCreate = (formData: any) => {
    let conf = createDetailAccomodationInspectionImageConf({
      ...formData,
      image: currentFileRef.current,
    });
    conf.onUploadProgress = (e: any) => {
      setShowProg({ display: true, body: e });
      if (e.progress === 1) {
        setShowProg({ display: false, body: { progress: 0 } });
      }
      console.log("onUploadProgress", e);
    };
    fetchData(conf, onSuccess, onErrorsModal);
  };

  const onImageDelete = (editId: any) => {
    fetchData(
      deleteAccomodationInspectionImageConf(editId),
      onSuccess,
      onErrorsModal
    );
  };

  return (
    <>
      <div className="header-container">
        <h2 className="page-title">
          {t("accomodationInspection.image.doctitle")}
        </h2>
        {!isOperatore &&   <Button
          onClick={createImage}
          color={templateTableEditClass}
          style={{ float: "right", marginTop: "5px" }}
        >
          <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faPlus} />{" "}
          {t("accomodation.image.add")}
        </Button> }
      </div>
      <Table striped responsive>
        <thead>
          <tr style={{ whiteSpace: "break-spaces" }}>
            <th style={{ width: "60%" }}>{t("accomodation.image.preview")}</th>
            <th style={{ width: "15%" }} />
          </tr>
        </thead>
        <tbody>
          {images?.map((el: any) => (
            <tr>
              <td>
                {el.file && el.file.preview && (
                  <img
                    src={el.file.preview}
                    alt="preview"
                    style={{ maxWidth: "100%", height: "auto" }}
                  ></img>
                )}
              </td>
              <td>
                <ButtonGroup>
                  {el.file && (
                    <DownloadButton
                      inTable={true}
                      url={el.file.url}
                      filename={el.file.originalname}
                    />
                  )}
                  { isAdministrator && <DeleteButton entityType='accomodation-inspection-image' id={el.id} refresh={() => { onSuccess('x') }} /> }
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={showProg.display}>
        <ModalHeader>Stiamo caricando i documenti</ModalHeader>
        <ModalBody>
          <>
            "sarai indirizzato alla pagina non appena l'operazione sarà
            conclusa"
            <Progress
              animated
              color="success"
              min={0}
              max={100}
              value={showProg.body.progress * 100}
            />
          </>
        </ModalBody>
        <ModalFooter>
          <svg
            viewBox="25 25 50 50"
            className="svgcircle d-none d-md-inline mt-2"
          >
            <circle r="20" cy="50" cx="50"></circle>
          </svg>
        </ModalFooter>
      </Modal>
    </>
  );
}
