import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

const MobileButton = (props: any) => {
  let inTable = props.inTable;
  let iconSize = props.iconSize ? props.iconSize : "1x";
  if (props.children !== undefined) {
    return <Button {...props}>{props.children}</Button>;
  } else {
    let cleanedProp = JSON.parse(JSON.stringify(props));
    delete cleanedProp.icon;
    delete cleanedProp.text;
    delete cleanedProp.inTable;
    cleanedProp.onClick = props.onClick;

    return (
      <Button {...cleanedProp}>
        <>
          <FontAwesomeIcon size={iconSize} icon={props.icon} />
          <span
            className={
              inTable !== undefined
                ? "d-md-none d-lg-inline"
                : "d-none d-md-inline"
            }
          >
            {props.text ? <>&nbsp;{props.text}</> : ""}
          </span>
        </>
      </Button>
    );
  }
};

export default MobileButton;
