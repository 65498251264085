import {
  faAddressBook,
  faEye,
  faListCheck,
  faPencil,
  faPlus,
  faRefresh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Table,
} from "reactstrap";
import { getUserListConf } from "../../api/userAPI";
import MobileButton from "../../components/mobile-button";
import PaginationUtility from "../../components/pagination";
import useRequest from "../../hook/use-request.hook";
import { DetailUser } from "../../model/user.model";
import {
  currentUserSelector,
  typeOptionsADMIN,
  typeOptionsSPORTELLISTA,
} from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { useAppUtilContext } from "../../util/app-util.context";
import {
  templateGoToActionClass,
  templateTableEditClass,
  templateTableShowClass,
} from "../../util/form.util";
import DeleteButton from "../../components/delete-button";

export default function HomeUserListPage(props: any) {
  const { withLink } = props;
  const { onErrorsModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<DetailUser[]>([]);
  const [name, setName] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [cod, setCod] = useState<any>(null);
  const [authorities, setAuthorities] = useState<any>(null);
  const [surname, setSurname] = useState<any>(null);

  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isOperatore = currentUser?.authorityChecks.isOperatore;
  const isSportellista = currentUser?.authorityChecks.isSportellista;

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const handleChange = (e: any) => {
    if (e.target.id === "name") setName(e.target.value);
    else if (e.target.id === "surname") setSurname(e.target.value);
    else if (e.target.id === "cod") setCod(e.target.value);
    else if (e.target.id === "email") setEmail(e.target.value);
    else if (e.target.id === "authorities")
      setAuthorities(e.target.value != 0 ? e.target.value : undefined);
  };

  const refreshFirstPage = () => {
    setPagination({
      page: 1,
      pageSize: 21,
      totalElements: null,
    });

    refresh(pagination);
  };

  useEffect(() => {
    if (!isAdministrator) setAuthorities("USER");
  }, [currentUser]);

  useEffect(() => {
    refreshFirstPage();
  }, []);

  const refresh = (pag: any) => {
    let query = "";
    if (email) query = query + "&email=" + email;
    if (cod) query = query + "&fiscalCode=" + cod;
    if (name) query = query + "&name=" + name;
    if (surname) query = query + "&surname=" + surname;
    if (authorities) query = query + "&authorities=" + authorities;

    fetchData(
      getUserListConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(data);
        setPagination({
          ...pag,
          ...{ totalElements: header["x-total-count"] },
        });
      },
      onErrorsModal
    );
  };

  let typeOptions: any = [];
  if (isAdministrator || isOperatore) {
    typeOptions = typeOptionsADMIN;
  } else if (isSportellista) {
    typeOptions = typeOptionsSPORTELLISTA;
  }

  return (
    <div className="col-lg-6 col-md-6 col-sm-6">
      <div className="card">
        <div className="container">
          <div className="header-container">
            <h2 className="page-title">
              <FontAwesomeIcon size="2x" icon={faAddressBook} />{" "}
              {t("home.utenti")}
              {withLink && (
                <Button
                  text="risposte"
                  onClick={() => navigate(`/user`)}
                  color={templateGoToActionClass}
                  style={{ float: "right", marginTop: "5px" }}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "1rem" }}
                    icon={faListCheck}
                  />{" "}
                  {t("home.utenti-all")}
                </Button>
              )}
            </h2>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <FormGroup>
                <Input
                  id="email"
                  name="email"
                  placeholder={t("user.register.email")}
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <FormGroup>
                <Input
                  id="cod"
                  placeholder={t("user.register.fiscalCode")}
                  name="cod"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
              <FormGroup>
                <Input
                  id="name"
                  placeholder={t("user.register.first_name")}
                  name="name"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              {isAdministrator && (
                <FormGroup>
                  <Input
                    id="authorities"
                    name="authorities"
                    type="select"
                    placeholder={t("user.register.authorities")}
                    onChange={handleChange}
                  >
                    <option key={0} value={0}>
                      {t("user.register.none")}
                    </option>
                    {typeOptions.map((it: any) => {
                      return (
                        <option key={it.id} value={it.id}>
                          {it.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              )}
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <MobileButton
                  color="primary"
                  icon={faSearch}
                  text={t("general.actions.search")}
                  onClick={refreshFirstPage}
                ></MobileButton>
                <div>
                  {" "}
                  {!isOperatore && (
                    <MobileButton
                      color="secondary"
                      outline
                      onClick={() => navigate(`/user/register`)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="d-none d-md-inline">
                        &nbsp; {t("general.buttons.create")}
                      </span>
                    </MobileButton>
                  )}{" "}
                  <MobileButton
                    onClick={() => refresh(pagination)}
                    disabled={loading}
                    color="secondary"
                    outline
                  >
                    {!loading ? (
                      <>
                        <FontAwesomeIcon icon={faRefresh} />
                      </>
                    ) : (
                      <>
                        {/* <Spinner size="sm">Loading...</Spinner> */}
                        <svg
                          viewBox="25 25 50 50"
                          className="svgcircle d-none d-md-inline mt-2"
                        >
                          <circle r="20" cy="50" cx="50"></circle>
                        </svg>
                      </>
                    )}
                  </MobileButton>
                </div>
              </div>
            </div>
          </div>
          {pagination.totalElements == 0 && (
            <div className="pb-3 pt-3 fs-5">
              {t("general.errors.no_items_found")}
            </div>
          )}
          {pagination.totalElements > 0 && (
            <div className="mt-2 row">
              <Table striped responsive>
                <thead>
                  <tr style={{ whiteSpace: "break-spaces" }}>
                    <th>{t("general.id")}</th>
                    <th>
                      {t("user.register.first_name")}{" "}
                      {t("user.register.last_name")}
                    </th>
                    <th>{t("user.register.fiscalCode")}</th>
                    <th>{t("user.register.email")}</th>
                    <th></th>
                    <th style={{ width: "60px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((it, idx) => {
                    return (
                      <tr key={it.id}>
                        <td style={{ width: "auto" }}>{it.id}</td>
                        <td style={{ width: "auto" }}>
                          {it.firstName} {it.lastName}
                        </td>
                        <td style={{ width: "auto" }}>{it.fiscalCode}</td>
                        <td style={{ width: "auto" }}>{it.email}</td>
                        <td style={{ width: "auto" }}>
                          {it.activated ? (
                            it.enabled ? (
                              <Badge color="success">{t("user.enabled")}</Badge>
                            ) : (
                              <Badge color="warning">
                                {t("user.disabled")}
                              </Badge>
                            )
                          ) : (
                            <Badge color="danger">{t("user.disactive")}</Badge>
                          )}{" "}
                        </td>

                        <td>
                          <ButtonGroup style={{ width: "100%" }}>
                            <MobileButton
                              inTable={true}
                              icon={faEye}
                              className="d-block"
                              style={{ width: "100%" }}
                              color={templateTableShowClass}
                              size="sm"
                              id={"view" + it.id}
                              onClick={() => navigate(`/user/${it.id}`)}
                            />
                            {!isOperatore && (
                              <MobileButton
                                inTable={true}
                                icon={faPencil}
                                className="d-block"
                                style={{ width: "100%" }}
                                color={templateTableEditClass}
                                size="sm"
                                id={"view" + it.id}
                                onClick={() => navigate(`/user/${it.id}/edit`)}
                              />
                            )}
                            {isAdministrator && ( <DeleteButton entityType='user' id={it.id} refresh={() => { refresh(pagination) }} /> )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}

          <div className="pb-5">
            <PaginationUtility
              {...pagination}
              onSizeChange={(n: any) => {
                if (pagination.pageSize !== n)
                  refresh({ ...pagination, ...{ page: 1, pageSize: n } });
              }}
              onChange={(n: any) => {
                if (pagination.page !== n)
                  refresh({ ...pagination, ...{ page: n } });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
