import {
  CreateSurveyAnswer,
  EditSurveyAnswer,
} from "../model/survey-answer.model";
import { defaultHeaders } from "./common";

import { AxiosRequestConfig } from "axios";
export const getSurveyAnswerListConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: "get",
    url: "/api/survey-answer?page=" + page + "&size=" + size + query,
    headers: defaultHeaders,
  };
};

export const getDetailSurveyAnswerConf = (id: string) => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/survey-answer/${id}`,
    headers: defaultHeaders,
  };
};
export const getPrintSurveyAnswerConf = (id: string): AxiosRequestConfig => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/survey-answer/${id}/print`,
    headers: defaultHeaders,
    responseType: "arraybuffer",
  };
};

export const createDetailSurveyAnswerConf = (
  surveyAnswer: CreateSurveyAnswer
) => {
  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/survey-answer`,
    headers: defaultHeaders,
    data: surveyAnswer,
  };
};
export const updateDetailSurveyAnswerConf = (
  surveyAnswer: EditSurveyAnswer
) => {
  return {
    method: "put",
    maxBodyLength: Infinity,
    url: `/api/survey-answer/${surveyAnswer.id}`,
    headers: defaultHeaders,
    data: JSON.stringify(surveyAnswer),
  };
};

export const sendCodeSurveyAnswerConf = (
  id: string,
  phoneNumber: any,
  fiscalCode: any,
  email: any
) => {
  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/survey-answer/${id}/send-code`,
    headers: defaultHeaders,
    data: {
      phoneNumber,
      fiscalCode,
      email,
    },
  };
};
export const confirmCodeSurveyAnswerConf = (id: number, code: string) => {
  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/survey-answer/verify-code`,
    headers: defaultHeaders,
    data: {
      answerId: id,
      code: code,
    },
  };
};

export const postArchiveSurveyAnswer = (id: number) => {
  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/survey-answer/${id}/archive`,
    headers: defaultHeaders,
  };
};
