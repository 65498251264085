import { forwardRef } from "react";
const FileWidget = forwardRef((props: any, ref) => {
  const { id, required, onChange, onFileChange } = props;

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    onFileChange(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64File = reader.result;
        let base64 = null;
        let arr = base64File?.toString().split(";");
        if (arr && arr.length > 1) {
          base64 = `${arr[0]};name=${file.name};${arr[1]}`;
        }
        onChange(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        id={id}
        onChange={handleFileChange}
        required={required}
      />
    </div>
  );
});

export default FileWidget;
