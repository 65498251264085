import { AxiosRequestConfig } from "axios";
import {
  CreateAccomodationImage,
  DetailAccomodationImage,
} from "../model/accomodation-image.model";
import { defaultHeaders } from "./common";

export const getAccomodationImageListConf = (query: string) => {
  return {
    method: "get",
    url: "/api/accomodation-image" + query,
    headers: defaultHeaders,
  };
};

export const getDetailAccomodationImageConf = (id: string) => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/accomodation-image/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailAccomodationImageConf = (
  accomodationImage: DetailAccomodationImage
) => {
  return {
    method: "put",
    maxBodyLength: Infinity,
    url: `/api/accomodation-image/${accomodationImage.id}`,
    headers: defaultHeaders,
    data: accomodationImage,
  };
};

export const createDetailAccomodationImageConf = (
  accomodationImage: CreateAccomodationImage
): AxiosRequestConfig => {
  const { date, accomodationId, image } = accomodationImage;
  const form = new FormData();
  form.append("accomodationId", accomodationId.toString());
  form.append("date", date);
  image && form.append("file", image);

  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/accomodation-image`,
    headers: {
      "content-type": "multipart/form-data",
    },
    data: form,
  };
};
export const deleteAccomodationImageConf = (id: string) => {
  return {
    method: "delete",
    maxBodyLength: Infinity,
    url: `/api/accomodation-image/` + id,
    headers: defaultHeaders,
  };
};
