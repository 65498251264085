import {
  CreateSurveyTemplate,
  DetailSurveyTemplate,
} from "../model/survey-template.model";
import { defaultHeaders } from "./common";

export const getSurveyTemplateListConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: "get",
    url: "/api/survey-template?page=" + page + "&size=" + size + query,
    headers: defaultHeaders,
  };
};
export const getSurveyTemplateListHomeConf = () => {
  return {
    method: "get",
    url: "/api/survey-template",
    headers: defaultHeaders,
  };
};

export const getSurveyTemplateLookupListConf = () => {
  return {
    method: "get",
    url: "/api/survey-template/lookup",
    headers: defaultHeaders,
  };
};
export const getSurveyTemplateEnabledListConf = () => {
  return {
    method: "get",
    url: "/api/survey-template/enabled",
    headers: defaultHeaders,
  };
};

export const getDetailSurveyTemplateConf = (id: string) => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/survey-template/${id}`,
    headers: defaultHeaders,
  };
};


export const updateDetailSurveyTemplateConf = (
  surveyTemplate: DetailSurveyTemplate
) => {
  return {
    method: "put",
    maxBodyLength: Infinity,
    url: `/api/survey-template/${surveyTemplate.id}`,
    headers: defaultHeaders,
    data: JSON.stringify(surveyTemplate),
  };
};
export const createDetailSurveyTemplateConf = (
  surveyTemplate: CreateSurveyTemplate
) => {
  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/survey-template`,
    headers: defaultHeaders,
    data: JSON.stringify(surveyTemplate),
  };
};
