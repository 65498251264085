import { Route } from "react-router-dom";
import { currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import ErrorBoundaryRoutes from "../../shared/error-boundary-routes";
import PageNotFound from "../../shared/page-not-found";
import AccomodationOwnerDetailPage from "./accomodation-owner-detail.page";
import AccomodationOwnerListPage from "./accomodation-owner-list.page";

const AccomodationOwnerRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;

  return (
    <ErrorBoundaryRoutes>
      {currentUser && (isSportellista || isAdministrator || isOperatore) && (
        <>
          <Route path="" element={<AccomodationOwnerListPage />} />
          {!isOperatore && (
            <Route
              path="/create"
              element={<AccomodationOwnerDetailPage edit />}
            />
          )}
          <Route path=":id" element={<AccomodationOwnerDetailPage />} />
          {!isOperatore && (
            <Route
              path=":id/edit"
              element={<AccomodationOwnerDetailPage edit />}
            />
          )}
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default AccomodationOwnerRoutes;
