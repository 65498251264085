import {
  faBars,
  faBell,
  faBuilding,
  faGauge,
  faLandmark,
  faList,
  faMapPin,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useState } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import SidebarFooter from "./sidebar-footer";

export default function AppSideBar() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const initialCollapsed = screenWidth < 768;
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const navigate = useNavigate();

  return (
    <Sidebar collapsed={collapsed} style={{ height: "100vh" }}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div style={{ flex: 1, marginBottom: "32px" }}>
          <Menu>
            <MenuItem
              icon={<FontAwesomeIcon icon={faBars} />}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              style={{ textAlign: "center" }}
            ></MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={faGauge} />}
              onClick={() => navigate("/")}
            >
              {t("sidebar.dashboard")}
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={faBell} />}
              onClick={() => navigate("/facility-record-request")}
            >
              {t("sidebar.requests")}
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={faUserTie} />}
              onClick={() => navigate("/solicitor")}
            >
              {t("sidebar.solicitors")}
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={faBuilding} />}
              onClick={() => navigate("/facility")}
            >
              {t("sidebar.facilities")}
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={faLandmark} />}
              onClick={() => navigate("/company")}
            >
              {t("sidebar.companies")}
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={faList} />}
              onClick={() => navigate("/facility-type")}
            >
              {t("sidebar.facility-types")}
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={faMapPin} />}
              onClick={() => navigate("/place")}
            >
              {t("sidebar.places")}
            </MenuItem>
          </Menu>
        </div>
        <SidebarFooter collapsed={collapsed} />
      </div>
    </Sidebar>
  );
}
