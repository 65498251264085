import { Theme as Bootstrap4Theme } from "@rjsf/bootstrap-4";
import { withTheme } from "@rjsf/core";
import { t } from "i18next";
import {
  ArrayFieldTemplate,
  FieldTemplate,
  ObjectFieldTemplate,
} from "../components/TemplateFieldShifter";

export const templates = {
  FieldTemplate,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
};

export const FormWithBootstrap = withTheme(Bootstrap4Theme);

export const patternZip = "^[0-9]{5}$";
export const patternCodiceFiscale =
  "^[A-Z]{6}\\d{2}[A-Z]\\d{2}[A-Z]\\d{3}[A-Z]$";
export const patternNumeroTelefono =
  "^[+][0-9]{3}[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";

export const transformErrors = (errors: any) => {
  return errors.map((error: any) => {
    if (error.name === "required") {
      error.message = error.message.replace(
        "must have required property",
        t("general.errors.required")
      );
    }
    if (error.name === "minLength") {
      error.message = t("general.errors.minLength", {
        limit: error.params.limit,
      });
    }
    if (error.name === "minItems") {
      error.message = t("general.errors.minItems", {
        limit: error.params.limit,
      });
    }
    if (error.name === "format" && error.params.format === "email") {
      error.message = t("general.errors.invalid_email");
    }
    if (error.name === "pattern" && error.property === ".zip") {
      error.message = t("general.errors.cap");
    }
    if (error.name === "pattern" && error.property === ".fiscalCode") {
      error.message = t("general.errors.fiscalCode");
    }
    if (
      error.name === "pattern" &&
      (error.property.startsWith(".referentPhoneNumbers") ||
        error.property === ".phoneNumber")
    ) {
      error.message = t("general.errors.phoneNumber");
    }

    return error;
  });
};

export const defaultUiSchema = {
  "ui:submitButtonOptions": {
    submitText: t("general.buttons.send"),
  },
};

export function objectDefined<T>(obj: T): Partial<T> {
  const acc: Partial<T> = {};
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) acc[key] = obj[key];
  }
  return acc;
}
