import { t } from "i18next";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export interface ModalHandle {
  open: (
    title: string,
    body: string | Node,
    footer: string | Node,
    action?: string | Node | Element, 
    headerClass?: string
  ) => void;
  close: () => void;
}
const GenericModal = forwardRef<ModalHandle>((props: {}, ref) => {
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFooter, setModalFooter] = useState("");
  const [modalHeaderClass, setModalHeaderClass] = useState("");
  const [modalAction, setModalAction] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  useImperativeHandle(ref, () => ({
    open(title: string, body: any, footer: any, action?: any, headerClass?: any) {
      setModalTitle(title);
      setModalBody(body);
      setModalFooter(footer);
      setModalAction(action);
      setModalHeaderClass(headerClass);
      setModal(true);
    },
    close() {
      setModalTitle("");
      setModalBody("");
      setModalFooter("");
      setModalAction("");
      setModalAction("");
      setModal(false);
    },
  }));

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className={modalHeaderClass? modalHeaderClass : ""} toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBody ? modalBody : ""}</ModalBody>
        <ModalFooter>
          {modalFooter ? (
            modalFooter
          ) : (
            <Button color="secondary" onClick={toggle}>
              {t("general.buttons.close")}
            </Button>
          )}
          {modalAction ? modalAction : <></>}
        </ModalFooter>
      </Modal>
    </>
  );
});

export default GenericModal;
