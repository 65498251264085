export const defaultHeaders = {
  "Content-Type": "application/json",
};



export const deleteEntityConf = (entity: string, id: string|number) => {
  return {
    method: "delete",
    maxBodyLength: Infinity,
    url: `/api/${entity}/${id}`,
    headers: defaultHeaders,
  };
};
