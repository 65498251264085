import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { BsPlus } from "@react-icons/all-files/bs/BsPlus";

import {
  ArrayFieldTemplateItemType,
  ArrayFieldTemplateProps,
  FormContextType,
  getTemplate,
  getUiOptions,
  RJSFSchema,
  StrictRJSFSchema,
} from "@rjsf/utils";
import ArrayFieldItemTemplate from "./ArrayFieldItemTemplate";

export default function ArrayFieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: ArrayFieldTemplateProps<T, S, F>) {
  const {
    canAdd,
    disabled,
    idSchema,
    uiSchema,
    items,
    onAddClick,
    readonly,
    registry,
    required,
    schema,
    title,
  } = props;
  const uiOptions = getUiOptions<T, S, F>(uiSchema);

  const ArrayFieldDescriptionTemplate = getTemplate<
    "ArrayFieldDescriptionTemplate",
    T,
    S,
    F
  >("ArrayFieldDescriptionTemplate", registry, uiOptions);

  const ArrayFieldTitleTemplate = getTemplate<
    "ArrayFieldTitleTemplate",
    T,
    S,
    F
  >("ArrayFieldTitleTemplate", registry, uiOptions);

  const testoAdd =
    uiSchema &&
    uiSchema["ui:options"] &&
    uiSchema["ui:options"].addButtonText !== undefined ? (
      <>{uiSchema["ui:options"].addButtonText}</>
    ) : (
      <BsPlus />
    );

  return (
    <div>
      <Row className="p-0 m-0">
        <Col className="p-0 m-0">
          <ArrayFieldTitleTemplate
            idSchema={idSchema}
            title={uiOptions.title || title}
            schema={schema}
            uiSchema={uiSchema}
            required={required}
            registry={registry}
          />
          <h4>{uiOptions.title || title}</h4>
          <hr />
          <ArrayFieldDescriptionTemplate
            idSchema={idSchema}
            description={uiOptions.description || schema.description}
            schema={schema}
            uiSchema={uiSchema}
            registry={registry}
          />
          <Container
            fluid
            key={`array-item-list-${idSchema.$id}`}
            className="p-0 m-0"
          >
            {items &&
              items.map(
                ({
                  key,
                  ...itemProps
                }: ArrayFieldTemplateItemType<T, S, F>) => (
                  <ArrayFieldItemTemplate
                    uiSchemaArray={uiSchema}
                    key={key}
                    {...itemProps}
                  />
                )
              )}
            {canAdd && (
              <Button
                className="array-item-add"
                style={{ marginLeft: "auto" }}
                onClick={onAddClick}
                disabled={disabled || readonly}
              >
                {testoAdd}
              </Button>
            )}
            <hr />
          </Container>
        </Col>
      </Row>
    </div>
  );
}
