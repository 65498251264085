import { IoIosRemove } from "@react-icons/all-files/io/IoIosRemove";
import { FormContextType, RJSFSchema, StrictRJSFSchema } from "@rjsf/utils";
import { CSSProperties } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default function ArrayFieldItemTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: any) {
  const {
    children,
    disabled,
    hasToolbar,
    hasCopy,
    hasMoveDown,
    hasMoveUp,
    hasRemove,
    index,
    onCopyIndexClick,
    onDropIndexClick,
    onReorderClick,
    readonly,
    registry,
    uiSchema,
    uiSchemaArray,
  } = props;
  const { CopyButton, MoveDownButton, MoveUpButton } =
    registry.templates.ButtonTemplates;
  const btnStyle: CSSProperties = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: "bold",
  };

  const testoAdd =
    uiSchemaArray &&
    uiSchemaArray["ui:options"] &&
    uiSchemaArray["ui:options"].removeButtonText !== undefined ? (
      <>{uiSchemaArray["ui:options"].removeButtonText}</>
    ) : (
      <IoIosRemove />
    );

  return (
    <div>
      <Row className="mb-2  d-flex align-items-center">
        <Col style={{ flexGrow: "1" }}>
          <div
            className="card"
            style={{ boxShadow: "0 0 0 .25rem rgba(13, 110, 253, .25)" }}
          >
            <div className="card-body">{children}</div>
          </div>
        </Col>
        <Col style={{ flexGrow: "0" }} className="py-4">
          {hasToolbar && (
            <div className="d-flex flex-row">
              {(hasMoveUp || hasMoveDown) && (
                <div className="m-0 p-0">
                  <MoveUpButton
                    className="array-item-move-up"
                    style={btnStyle}
                    disabled={disabled || readonly || !hasMoveUp}
                    onClick={onReorderClick(index, index - 1)}
                    uiSchema={uiSchema}
                    registry={registry}
                  />
                </div>
              )}
              {(hasMoveUp || hasMoveDown) && (
                <div className="m-0 p-0">
                  <MoveDownButton
                    style={btnStyle}
                    disabled={disabled || readonly || !hasMoveDown}
                    onClick={onReorderClick(index, index + 1)}
                    uiSchema={uiSchema}
                    registry={registry}
                  />
                </div>
              )}
              {hasCopy && (
                <div className="m-0 p-0">
                  <CopyButton
                    style={btnStyle}
                    disabled={disabled || readonly}
                    onClick={onCopyIndexClick(index)}
                    uiSchema={uiSchema}
                    registry={registry}
                  />
                </div>
              )}
              {hasRemove && (
                <div className="m-0 p-0">
                  <Button
                    variant="danger"
                    className="array-item-add"
                    onClick={onDropIndexClick(index)}
                    disabled={disabled || readonly}
                  >
                    {testoAdd}
                  </Button>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
