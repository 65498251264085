import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { setOnline } from './redux/reducers/networkStatusSlice';
import { setupAxiosInterceptors } from './axios/axiosSetting';

import { persistor, store } from './redux/store';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
window.addEventListener('online', (event) => {
 // console.log('online');
  store.dispatch(setOnline(true));
});

window.addEventListener('offline', (event) => {
 // console.log('offline');
  store.dispatch(setOnline(false));
});
setupAxiosInterceptors(store);
root.render(
  <React.StrictMode>
    <div className="notranslate">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>{' '}
    </div>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
