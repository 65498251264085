import { AxiosRequestConfig } from "axios";
import {
  CreateAppointmentDocument,
  DetailAppointmentDocument,
  UpdateAppointmentDocument,
} from "../model/appointment-document.model";
import { defaultHeaders } from "./common";
import { Update } from "@reduxjs/toolkit";

export const getAppointmentDocumentListConf = (query: string) => {
  return {
    method: "get",
    url: "/api/appointment-document" + query,
    headers: defaultHeaders,
  };
};

export const getDetailAppointmentDocumentConf = (id: string) => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/appointment-document/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailAppointmentDocumentConf = (
  appointmentDocument: UpdateAppointmentDocument
) => {
  return {
    method: "put",
    maxBodyLength: Infinity,
    url: `/api/appointment-document/${appointmentDocument.id}`,
    headers: defaultHeaders,
    data: appointmentDocument,
  };
};

export const createAppointmentDocumentConf = (
  appointmentDocument: CreateAppointmentDocument
): AxiosRequestConfig => {
  const { appointmentId, notes, title, file } = appointmentDocument;
  const form = new FormData();
  form.append("appointmentId", appointmentId.toString());
  file && form.append("file", file);
  form.append("title", title);
  form.append("notes", notes);
  console.log("form", form);
  return {
    method: "post",
    maxBodyLength: Infinity,
    url: `/api/appointment-document`,
    headers: {
      "content-type": "multipart/form-data",
    },
    data: form,
  };
};
export const deleteAppointmentDocumentConf = (id: string) => {
  return {
    method: "delete",
    maxBodyLength: Infinity,
    url: `/api/appointment-document/` + id,
    headers: defaultHeaders,
  };
};
