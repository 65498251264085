import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import {
  getDetailSurveyAnswerConf,
  getPrintSurveyAnswerConf,
  updateDetailSurveyAnswerConf,
} from "../../api/surveyAnswerAPI";
import BreadcrumbsUtility from "../../components/breadcrumb";
import useRequest from "../../hook/use-request.hook";
import { DetailSurveyAnswer } from "../../model/survey-answer.model";
import { DetailSurveyTemplate } from "../../model/survey-template.model";
import { currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { useAppUtilContext } from "../../util/app-util.context";
import SurveyTemplateStatic from "../survey-template/survey-template-static";
import AddAnswerDocument from "./survey-answers-document.view";
import SelectUserView from "../../components/SelectUserView";

export const bonificaAnswer = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === "string" && obj[key] === "") {
      obj[key] = undefined; // Imposta la stringa vuota a null
    } else if (obj[key] !== null && typeof obj[key] === "object") {
      bonificaAnswer(obj[key]); // Ricorsione per oggetti annidati
    }
  }
  return obj;
};

export default function SurveyTemplateDetailPage(props: any) {
  const { edit } = props;
  let { id } = useParams();
  const { showModal, onErrorsModal } = useAppUtilContext();
  const { fetchData: fetchDataDetail } = useRequest<any>();
  const [answer, setAnswer] = useState<DetailSurveyAnswer>();
  const [entity, setEntity] = useState<DetailSurveyTemplate>();
  const [jsonSchema, setJsonSchema] = useState<any[]>();
  const [uiSchema, setUiSchema] = useState<any>();
  const [tradSchema, setTradSchema] = useState<any[]>();
  const [datas, setDatas] = useState<any>([]);
  const [userId, setUserId] = useState<number>();

  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailSurveyAnswerConf(id),
        onSuccessAnswer,
        onErrorsModal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const printAnswer = () => {
    if (id) {
      fetchDataDetail(
        getPrintSurveyAnswerConf(id),
        (data: any, header: any) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = "stampa.docx";
          a.click();
        },
        onErrorsModal
      );
    }
  };

  useEffect(() => {
    if (entity) {
      let schemaObj = entity.jsonSchema;
      let uiObj = entity.uiSchema;
      let tradObj = entity.tradSchema;
      setJsonSchema(schemaObj);
      setUiSchema(uiObj);
      setTradSchema(tradObj);
    }
  }, [entity]);

  const onSuccessAnswer = (ans: DetailSurveyAnswer) => {
    let jsonDatasClean = bonificaAnswer(ans.jsonDatas);
    setAnswer({ ...ans, ...{ jsonDatas: jsonDatasClean } });
    setDatas(jsonDatasClean);
    setEntity(ans.surveyTemplate);
    setUserId(ans.userId);
  };

  const onSuccessEdit = (data: any) => {
    let message = data.message;
    showModal(t("modal.request_success"), message, null);
  };

  const maxstep = Array.isArray(jsonSchema) ? jsonSchema.length : 1;

  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;
  console.log("userId", userId);
  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          { text: t("survey-answer.list.title"), link: "/survey-answer" },
          { text: t("survey-answer.detail.title") },
        ]}
      />
      <div className="header-container">
        <h2 className="page-title">{t("survey-answer.detail.title")}</h2>

        {(isSportellista || isAdministrator || isOperatore) && (
          <Button
            text="print"
            onClick={printAnswer}
            color={"dark"}
            style={{ marginBottom: "19px", marginTop: "19px" }}
          >
            <FontAwesomeIcon
              style={{ marginRight: "1rem" }}
              icon={faListCheck}
            />{" "}
            {t("general.buttons.print")}
          </Button>
        )}
      </div>
      <SelectUserView
        userId={userId}
        handleChange={setUserId}
        readonly={edit ? false : true}
      ></SelectUserView>

      {maxstep > 0 && (
        <div>
          <SurveyTemplateStatic
            onSubmit={(newdata: any) => {
              if (edit) {
                fetchDataDetail(
                  updateDetailSurveyAnswerConf({
                    jsonDatas: newdata,
                    id: answer?.id as number,
                    surveyTemplateId: answer?.surveyTemplate.id,
                    userId: userId,
                  }),
                  onSuccessEdit,
                  onErrorsModal
                );
              }
            }}
            jsonSchema={jsonSchema}
            uiSchema={uiSchema}
            tradSchema={tradSchema}
            formDatas={datas}
            isTradEnabled={false}
            isReadMode={edit ? false : true}
          />
          {/* && answer?.verificationStatus == 'VERIFIED' */}
          {!edit && <AddAnswerDocument />}
        </div>
      )}
    </div>
  );
}
