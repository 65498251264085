import { AxiosRequestConfig } from "axios";
import { defaultHeaders } from "./common";

export const getDownloadFileConf = (url: string): AxiosRequestConfig => {
  return {
    method: "get",
    url: url,
    headers: defaultHeaders,
    responseType: "blob",
  };
};
